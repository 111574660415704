import { render, staticRenderFns } from "./sxgldxallist.vue?vue&type=template&id=61fc9600&scoped=true"
import script from "./sxgldxallist.vue?vue&type=script&lang=js"
export * from "./sxgldxallist.vue?vue&type=script&lang=js"
import style0 from "./sxgldxallist.vue?vue&type=style&index=0&id=61fc9600&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fc9600",
  null
  
)

export default component.exports