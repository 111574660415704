<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <span style="margin: 0 0 10px 0;">选择组织：</span>
        <!-- <el-cascader ref="firstCascader" :options="ckdTreeData" :props="defaultProps" v-model="deptId"
					@change="searchData" :show-all-levels="false" clearable placeholder="请选择" filterable
					style="width: 150px;margin-right:10px" size="small">
        </el-cascader>-->

        <tchtree v-model="deptId" @change="searchData" style="margin: 0 0 10px 0;"></tchtree>

        <span v-if="$store.state.pmid == 215" style="margin: 0 10px 10px 10px;">届次：</span>
        <el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
          placeholder="请选择" style="max-width:200px;margin: 0 10px 10px 0;" @change="searchData">
          <el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
          </el-option>
        </el-select>

        <span style="margin: 0 10px 10px 0;">请输入关键字：</span>
        <el-input v-model="searchForm.keyword" size="small" placeholder="请输入关键字，多关键字使用空格隔开" clearable
          style="width: 200px;margin: 0 10px 10px 0;" />
        <span style="margin: 0 0 10px 0;">状态：</span>
        <el-select v-model="searchForm.biye" size="small" style="width:100px;margin: 0 10px 10px 0;">
          <el-option :value="0" label="未毕业"></el-option>
          <el-option :value="1" label="已毕业"></el-option>
        </el-select>

        <el-button size="small" icon="el-icon-search" @click="searchData()" type="primary"
          style="margin: 0 10px 10px 0;">查询</el-button>
      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">

        <el-button size="small" icon="el-icon-guide" @click="groupStudents" style="margin: 0 10px 0 0;">批量挂靠</el-button>
        <el-button size="small" icon="el-icon-upload2" @click="importTeacherShow = true"
          style="margin: 0 10px 0 0;">导入学生</el-button>
        <el-button size="small" icon="el-icon-plus" @click="addStudents" style="margin: 0 10px 0 0;">添加单个学生</el-button>

        <div v-if="false" class="flexStart" style="margin: 0 10px 0 0;cursor: pointer;"
          @click="showExpand = !showExpand">
          {{ showExpand ? '展开' : '收起' }}&nbsp;&nbsp;
          <i v-if="showExpand" class="el-icon-arrow-down"></i>
          <i v-if="!showExpand" class="el-icon-arrow-up"></i>
        </div>

        <!--          <el-button size="small" icon="el-icon-download" @click="exportPdfs()" style="margin-left: 10px;">批量导出实习手册</el-button>-->
        <el-button size="small" icon="el-icon-download" @click="exportXls('/api/export_sxmc_xls')"
          style="margin: 0 10px 0 0;">导出实习生名册</el-button>

        <el-button size="small" icon="el-icon-download" @click="exportXls('/api/export_stu_list')"
          style="margin: 0 10px 0 0;">导出学生列表（按导入模板导出）</el-button>
      </div>


      <!--      </div>-->
    </el-row>
    <div class="dividerBar"></div>

    <!-- <el-row type="flex" style=" font-size: 14px;"> -->
    <!-- <el-col :span="8">
					<el-row>
						<el-col :span="22">

							<el-card class="box-card">
								<div slot="header" class="clearfix">
									<span @click="setBiyeType" style="cursor: pointer;" title="点击切换毕业状态">
										{{ biye === '' ? '全部' : '' }}
										{{ biye === 0 ? '实习' : '' }}
										{{ biye === 1 ? '毕业' : '' }}
										部门列表</span>
								
								</div>

								<div class="treeContainer" style='height:75vh;overflow: auto;'>
									<el-tree :data="treeData" :props="firstCascaderProps" node-key="id"
										:default-expand-all="true" :expand-on-click-node="false"
										@node-click="handleNodeClick">
										<div class="custom-tree-node flexBetween" slot-scope="{ node, data }"
											style="width: 100%;">
											<div>
												<span :style="{ backgroundColor: node.data.biye == 1 ? '#ccc' : '' }">{{
														node.data.name
												}}({{ node.data.stu_count ? node.data.stu_count : '0' }}人)</span>

												<span class="nodetag" style="background-color:plum"
													v-if="node.data.type == 'bj'">班</span>
												<span class="nodetag" style="background-color:purple"
													v-if="node.data.type == 'sxxz'">组</span>
											</div>
											<div>
												<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
													icon="el-icon-info" iconColor="red" @confirm="handleDel(data)"
													placement="left" title="是否确定删除？">
													<a slot="reference" size="small"
														v-if="node.data.type == 'sxxz' || node.data.type == 'bj'"
														style="color: red;font-size: 12px;margin-right: 10px;">
														<i class="el-icon-delete" title="删除节点"></i>
													</a>
												</el-popconfirm>
												<el-button @click="handleModify(data)"
													v-if="node.data.type == 'sxxz' || node.data.type == 'bj'"
													type="text" size="small">
													<i class="el-icon-edit" title="编辑节点"></i>
												</el-button>
												<el-button @click="handleAdd(data)" type="text" size="small"
													v-if="node.data.type == 'jc' || node.data.type == 'nj'">
													<i class="el-icon-plus" title="添加下级"></i>
												</el-button>

											</div>



										</div>
									</el-tree>
								</div>
							</el-card>



						</el-col>
					</el-row>
        </el-col>-->
    <!-- <el-col :span="24" class="tabContainer" style="height: 100%;">
          <el-card class="box-card">
            <div class="treeContainer" style="height: 80vh; overflow: auto;"> -->
    <Table class="flex_1" style="width:100%;" :tableData="tableData" :page="page" @handleModify="handleModify"
      @handleDel="handleDel" @handleSelectionChange="handleSelectionChange" from="dept"></Table>
    <!-- </div>
          </el-card>
        </el-col>
      </el-row> -->
    <el-dialog title="班级详情" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog">
      <el-form v-if="isShowModal" :model="formData" ref="adminUserInfo" label-width="120px" style>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级部门">
              <el-cascader ref="firstCascader" :options="treeData" :disabled="modalTitle == '修改'" :props="defaultProps"
                v-model="formData.parent_id" :show-all-levels="false" @change="handleCascaderChange" placeholder="请选择"
                filterable style="width: 80%"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级类型" required>
              <el-select v-model="formData.type" placeholder="请选择类型" style="width:80%">
                <el-option label="班级" value="bj"></el-option>
                <el-option label="实习小组" value="sxxz"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="班级名称" required>
              <el-input v-model.trim="formData.name" style="width: 80%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级代码" required>
              <el-input v-model.trim="formData.code" style="width: 80%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="实习开始时间">
              <el-date-picker :readonly="formData.type == 'bj' || formData.type == 'sxxz'" value-format="yyyy-MM-dd"
                v-model="formData.practice_start_date" style="width: 80%" type="date"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实习结束时间">
              <el-date-picker :readonly="formData.type == 'bj' || formData.type == 'sxxz'" value-format="yyyy-MM-dd"
                v-model="formData.practice_end_date" style="width: 80%" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="排序号" prop="sort" required>
              <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                controls-position="right" />
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>

        <el-form-item label="班主任" required>
          <el-select v-model="formTeacher.bzr" filterable remote clearable reserve-keyword placeholder="请输入教师姓名"
            :remote-method="searchTeacher" style="width:80%">
            <el-option v-for="item in teacherList" :key="item.teacher_id"
              :label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
              :value="item.teacher_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指导教师">
          <el-select v-model="formTeacher.zdls" multiple filterable remote clearable reserve-keyword
            placeholder="请输入教师姓名" :remote-method="searchTeacher" style="width:80%">
            <el-option v-for="item in teacherList" :key="item.teacher_id"
              :label="item.username + (item.dept_name ? '[' + item.dept_name + ']' : '')"
              :value="item.teacher_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <TForm v-if="showTeacherInfo" :student_id="student_id" :dept_id="deptId" :dictArr="dictArr"></TForm>

    <stuTree v-if="DeptUserDialog"></stuTree>

    <el-dialog title="导入学生" :visible.sync="importTeacherShow" width="800px" :close-on-click-modal="false"
      :destroy-on-close="true" custom-class="cus_dialog">
      <div style="text-align:center">
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="handleXlsUpload" :show-file-list="false" name="image">
          <el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
        </el-upload>
        <el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
          <a href="/xls/学生信息导入模板.xlsx" style="text-decoration: none;color:inherit">下载学生信息导入模板</a>
        </el-button>
      </div>
      <el-table v-if="failedImportData.length > 0" :data="failedImportData" style="width: 100%;padding-top: 20px;">
        <el-table-column label="查看" width="80" type="expand">
          <template slot-scope="props">
            <div v-for="(value,index) in props.row.errors" :key="index" style="color:#99a9bf;line-height: 26px;">{{
              value }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="filename" label="导入文件">
          <template v-slot="scope">
            <el-link :href="scope.row.file_url" type="primary">{{ scope.row.filename }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="cdate" label="导入时间"></el-table-column>
        <el-table-column prop="username" label="导入人"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import User from "./components/User";
import Table from "./components/StudentTable";
import TForm from "./components/StudentForm";
import stuTree from "./components/stuTree.vue";
import tchtree from "../com/tchTree.vue";
import moment from "moment";

export default {
  components: {
    tchtree,
    User,
    Table,

    TForm,
    stuTree
  },
  data() {
    return {
      visible: false,
      isShowModal: false,
      isShowModalAuth: false,
      importTeacherShow: false,
      modalTitle: "添加",
      DeptUserDialog: false,
      activeRoleID: "",
      biye: 0,
      formData: {},
      formTeacher: {
        bzr: "",
        zdls: []
      },
      ckdTreeData: [],

      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true
      },

      activeName: "CN",
      tableData: [],

      teacherList: [],
      editUser: {},
      deptId: 0,
      editDeptRole: {},
      deptRoleList: [],
      isShowDeptRole: false,

      page: {},
      searchForm: {
        biye: 0,
        ktype: "stu_name",
        keyword: ""
      },
      multipleSelection: [],
      treeData: [],
      firstCascaderProps: {
        label: "name",
        children: "children"
      },
      DataAuth: [],
      showTeacherInfo: false,
      student_id: 0,
      showExpand: true,
      dictArr: [],
      schoolYears: [],
      failedImportData: [],

    };
  },

  mounted() {

    let _this = this
    this.getTree();
    this.getDeptRoles();
    this.getFailedImport()
    this.getSchoolYear().then(_ => {
      _this.getDict().then(rr => {
        _this.getStudents();
      });
    })

    if ($(window).width() < 1670) {
      $(".breadcrumbbox").hide();
    }
  },
  methods: {
    handleCommand(e) {
      // <el-dropdown-item command="groupStudents" icon="el-icon-guide">学生分组/挂靠
      // 						</el-dropdown-item>
      // 						<el-dropdown-item command="importStudent" divided icon="el-icon-upload2">导入学生</el-dropdown-item>
      // 						<el-dropdown-item command="downStuTemp" divided icon="el-icon-download">下载学生模板</el-dropdown-item>
      // 						<el-dropdown-item command="addStudents" divided icon="el-icon-plus">添加单个学生</el-dropdown-item>

      if (e == "groupStudents") {
        this.groupStudents();
      } else if (e == "addStudents") {
        this.addStudents();
      } else if (e == "importStudent") {
        $("#btnimport").click();
      } else if (e == "downStuTemp") {
        window.open("/xls/学生信息导入模板.xlsx", "_blank");
      }
    },
    handleXlsUpload(e) {
      this.$http.post("/api/import_student_xls", { url: e.src, filename: e.fileName }).then(res => {
        this.$message.success("导入成功");
        this.getStudents();
        this.importTeacherShow = false;
      }).catch(err => {
        this.getFailedImport()
      });
    },
    getSchoolYear() {
      return new Promise((resolve, reject) => {
        if (this.$store.state.pmid == 215) {

          let m = moment().format('M')
          let nowYear = moment().year()
          let schoolYears = []
          if (m >= 5) {
            nowYear++
          }
          for (let i = nowYear; i >= 2019; i--) {
            let s = (i - 1) + "-" + i
            let s_label = s + '学年(' + (i - 5) + '届)'
            schoolYears.push({ title: s_label, value: s })
          }
          this.schoolYears = schoolYears
          this.searchForm.school_year = schoolYears[0].value
        }

        resolve()
      })
    },
    getTree() {
      this.$http
        .post("/api/sys_office_tree", { parent_id: 0, biye: this.biye })
        .then(res => {
          this.treeData = res.data.tree;
          this.ckdTreeData = this.treeData;
        });
    },
    setBiyeType() {
      if (this.biye === 0) {
        this.biye = 1;
      } else if (this.biye === 1) {
        this.biye = "";
      } else if (this.biye === "") {
        this.biye = 0;
      }
      this.getTree();
    },
    searchData() {
      this.page.current_page = 1;
      this.getStudents();
    },
    searchTeacher(e) {
      let params = {
        page: 1,
        pagesize: 10,
        ktype: "username",
        keyword: e
      };
      // sys_dept_user_related_list
      this.$http.post("/api/t_sch_teachers_list", params).then(res => {
        for (let item of res.data.data) {
          if (item.dept_roles && item.dept_roles.length > 0) {
            item.dept_name = item.dept_roles
              .map(e => {
                return e.name;
              })
              .join(",");
          }
          item.teacher_id = item.id;
        }
        this.teacherList = res.data.data;
      });
    },
    getDict() {
      return new Promise(resolve => {
        //巡访形式&&巡访情况
        this.$http
          .post("/api/sys_param_list", { param_type: "'SHIXI_STATUS'" })
          .then(res => {
            let dictArr = [];
            res.data.map((item, index, arr) => {
              if (item.param_type == "SHIXI_STATUS") {
                dictArr.push(item);
              }
            });
            this.dictArr = dictArr;
            resolve();
          });
      });
    },
    getStudents() {
      let params = {
        page: this.page.current_page,
        pagesize: this.page.per_page ? this.page.per_page : 20,
        ktype: this.searchForm.ktype,
        keyword: this.searchForm.keyword,
        // dept_id: this.searchForm.keyword ? '' : this.deptId,
        // class_id: this.searchForm.keyword ? '' : this.deptId,
        // office_id: this.searchForm.keyword ? "" : this.deptId,
        class_ids: this.searchForm.keyword ? "" : this.deptId,
        biye: this.searchForm.biye,
        school_year: this.searchForm.school_year,
      };

      // sys_dept_user_related_list
      this.$http.post("/api/sx_students_list", params).then(res => {
        res.data.data &&
          res.data.data.map(a => {
            this.dictArr.map(d => {
              if (d.param_value == a.sxqx) {
                a.sxqxLabel = d.param_desc;
                //如果是实习中，检查是否在开始时间
                if (a.sxqx == 1 && a.start_time && a.end_time) {
                  let ntime = parseInt(
                    this.$moment(new Date())
                      .add("year", 0)
                      .format("YYYY-MM-DD")
                      .replace(/-/g, "")
                  );
                  let start_time = parseInt(a.start_time.replace(/-/g, ""));
                  let end_time = parseInt(a.end_time.replace(/-/g, ""));
                  // console.log(888,a.stu_name,a.sxqx,ntime,a.start_time)
                  if (ntime >= start_time && ntime <= end_time) {
                    console.log(111, a.stu_name);
                    // a.sxqxLabel = '实习中'
                  } else if (ntime > end_time) {
                    console.log(222, a.stu_name);
                    a.sxqxLabel = "实习结束";
                  } else if (ntime < start_time) {
                    console.log(333, a.stu_name);
                    a.sxqxLabel = "待实习";
                  }
                }
              }
            });
          });
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    addStudents() {
      // let ckdClass = this.$refs['firstCascader'].getCheckedNodes()

      // if (!ckdClass[0]) {
      // 	this.$message.error("请先选择班级")
      // 	return
      // }
      // ckdClass = ckdClass[0].data
      // if (ckdClass.type != 'bj') {
      // 	this.$message.error("只能在班级中添加学生")
      // 	return
      // }

      this.student_id = 0;
      this.showTeacherInfo = true;
    },

    getDeptRoles() {
      this.$http.post("/api/sys_dept_role_list").then(res => {
        this.deptRoleList = res.data.data;
      });
    },

    handleNodeClick(data) {
      // console.log(data);
      this.deptId = data.id;

      this.deptInfo = data;
      this.ckdTreeData = [data];
      this.getStudents();
    },
    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection = val;
    },
    formAdd() {
      this.formData = {
        parent_id: 0,
        sort: 1,
        type: "",
        name: "",
        roles: []
      };
      this.formData.type = "";
      this.modalTitle = "新增部门";
      this.isShowModal = true;
    },

    exportPdfs() {
      /** 导出学生实习手册
       * @param {*} opt.ids 学生ids (逗号隔开) (必传)
       * @param {*} opt.latest 是否是最新(0: 否 | 1: 是)
       * @returns
       */
      let arr = [];
      if (!this.multipleSelection || this.multipleSelection.length == 0) {
        this.$message.error("请先选择学生");
      } else {
        for (let stu of this.multipleSelection) {
          arr.push(stu.id);
        }
        if (arr.length > 0) {
          this.$http
            .post(this.$pdfUrl + "/api/export_stu_training_manual", {
              ids: arr.join(","),
              latest: 1
            })
            .then(res => {
              this.$message({
                type: "success",
                message: "生成成功!"
              });
            });
        }
      }
    },
    exportPdfs_sxsp() { },
    exportXls(url) {
       if (!this.deptId) {
        this.$message.warning("请先选择班级~");
        return;
      }
      this.$http.post(url, { class_id: this.deptId }).then(res => {
        let url = res.data.url
        url = url.substring(0, url.lastIndexOf('/') + 1) + encodeURIComponent(url.substring(url.lastIndexOf('/') + 1))
        window.open(url, "_blank");
      });
    },
    handleAdd(row) {
      this.formData = {
        parent_id: row.id,
        sort: 1,
        type: "",
        name: "",
        roles: []
      };
      this.modalTitle = "新增部门";
      this.isShowModal = true;
    },
    handleModify(row) {
      let formData = JSON.parse(JSON.stringify(row));
      this.formData = formData;

      let formTeacher = {
        bzr: "",
        zdls: []
      };

      if (formData.teachers) {
        for (let item of formData.teachers) {
          if (item.user_type == "bzr") {
            formTeacher.bzr = item.teacher_id;
          } else if (item.user_type == "zd") {
            formTeacher.zdls.push(item.teacher_id);
          }
        }
        this.teacherList = formData.teachers;
      }
      this.formTeacher = formTeacher;
      console.log(this.teacherList);
      this.isShowModal = true;
    },
    resetRoles() {
      let extItem = [];
      //删除移除的角色
      for (let er of this.DataAuth) {
        let ext = false;
        for (let r of this.formData.roles) {
          if (er.role_id == r) {
            ext = true;
            break;
          }
        }
        if (ext) {
          extItem.push(er);
        }
      }
      this.DataAuth = extItem;
      //添加新增的角色..
      for (let r of this.formData.roles) {
        let ext = false;
        for (let er of this.DataAuth) {
          if (r == er.role_id) {
            ext = true;
            break;
          }
        }
        if (!ext) {
          let role_name = "";
          for (let item of this.deptRoleList) {
            if (item.id == r) {
              role_name = item.role_name;
            }
          }
          this.DataAuth.push({
            role_id: r,
            role_name: role_name,
            dept_data: []
          });
        }
      }
    },
    handleDel(row) {
      this.$http
        .post("/api/t_sch_students_delete", {
          id: String(row.id)
        })
        .then(res => {
          this.$message.success("删除成功");
          this.getStudents();
        });
    },
    handleCascaderChange(e) {
      console.log(1, e);
    },

    submitForm(formName) {
      let params = {
        ...this.formData
      };
      if (!params.parent_id) {
        params.parent_id = 0;
      }
      if (!this.formData.name) {
        this.$message.error("请输入班级/小组名称");
        return;
      }
      if (!this.formData.type) {
        this.$message.error("请选择是班级还是小组");
        return;
      }
      if (!this.formData.code) {
        this.$message.error("请输入班级代码");
        return;
      }
      if (!this.formTeacher.bzr) {
        this.$message.error("请选择班主任");
        return;
      }

      this.$http.post("/api/sys_office_edit", params).then(res => {
        this.$message.success("保存成功");

        this.isShowModal = false;
        if (res.data.id) {
          this.formData.id = res.data.id;
        }

        if (this.formTeacher.bzr) {
          //直选一个的写法
          this.$http
            .post("/api/sys_teacher_class_related_edit", {
              class_id: this.formData.id,
              teacher_ids: this.formTeacher.bzr + "",
              user_type: "bzr"
            })
            .then(res => { });
        }
        if (this.formTeacher.zdls && this.formTeacher.zdls.length > 0) {
          this.$http
            .post("/api/sys_teacher_class_related_edit", {
              class_id: this.formData.id,
              teacher_ids: this.formTeacher.zdls.join(","),
              user_type: "zd"
            })
            .then(res => { });
        }
        setTimeout(() => {
          this.getTree();
        }, 500);
      });
    },

    getUnique2(arr) {
      const map = {};
      // 1、把数组元素作为对象的键存起来（这样就算有重复的元素，也会相互替换掉）
      arr.forEach(item => (map[JSON.stringify(item)] = item));

      // 2、再把对象的值抽成一个数组返回即为不重复的集合
      return Object.keys(map).map(key => map[key]);
    },

    showDeptRole(row) {
      this.deptId = row.id;
      this.isShowDeptRole = true;
    },

    showRoleAuth() {
      if (this.DataAuth.length == 0) {
        this.$message.error("请选择职位");
      } else {
        this.isShowModalAuth = true;
        setTimeout(() => {
          if (this.DataAuth.length > 0) {
            this.$refs["dataauthtree"].setCheckedKeys(
              this.DataAuth[0].dept_data
            );
          }
        }, 300);
      }
    },
    setOk() {
      this.isShowModalAuth = false;
    },
    handleRoleChange() {
      for (let tab of this.DataAuth) {
        if (tab.role_id == this.activeRoleID) {
          this.$refs["dataauthtree"].setCheckedKeys(tab.dept_data);
          break;
        }
      }
    },
    checkAuthData(a, b, c) {
      for (let tab of this.DataAuth) {
        if (tab.role_id == this.activeRoleID) {
          tab.dept_data = this.$refs["dataauthtree"].getCheckedKeys();
          break;
        }
      }
    },

    groupStudents() {
      if (this.multipleSelection.length > 0) {
        this.DeptUserDialog = true;
      } else {
        this.$message.error("请先选中学生");
      }
    },
    saveGroupUser(class_id) {
      console.log("分到这个小组：" + class_id);
      this.$http
        .post("/api/get_sxgroup_stus", { pagesize: 2000, class_id: class_id })
        .then(res => {
          let stuDic = {};
          for (let stu of res.data) {
            stuDic[stu.id] = 1;
          }
          let arr = [];
          console.log(this.multipleSelection);
          console.log(stuDic);
          for (let stu of this.multipleSelection) {
            if (!stuDic[stu.id]) {
              arr.push(stu.id);
            }
          }

          if (arr.length > 0) {
            this.$http
              .post("/api/sys_stu_class_related_edit", {
                ids: arr.join(","),
                class_id: class_id
              })
              .then(rdata => {
                this.getStudents();
                this.$message.success("分组成功！");
              });
          } else {
            this.$message.error("挂靠失败，已经挂靠该分组");
          }
        });
    },
    getFailedImport() {
      this.$http.post('/api/failed_import_list', {module:'student_import'}).then(res => {
        res.data.map(r => {
          r.errors = JSON.parse(r.errors)
        })
        this.failedImportData = res.data
      })
    },
  }
};
</script>
<style scoped lang="less" type="text/less">
.app-container {
  .treeContainer {
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(220, 223, 230, 0.6);
    margin-right: 20px;
    padding-right: 10px;
    overflow: auto;

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}

.nodetag {
  display: inline-block;
  padding: 0 5px;
  font-size: 12px;
  transform: scale(0.8, 0.8);
  background-color: #409eff;
  color: #fff;
  border-radius: 4px;
}
</style>
