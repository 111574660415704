<template>
  <div class="pageContainer targetPage flexCloumn dialogzindex">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <el-input placeholder="学号或学生姓名" v-model="searchForm.stu_str" size="small" class="input-with-select"
          style="width:200px" clearable></el-input>

        <el-input placeholder="班主任或指导老师或创建人" v-model="searchForm.teacher_str" size="small" class="input-with-select"
          style="width:200px;margin-left: 10px" clearable></el-input>
        <el-input placeholder="班级名称" v-model="searchForm.class_name" size="small" class="input-with-select"
          style="width:200px;margin-left: 10px" clearable></el-input>
        <el-date-picker size="small" v-model="dateArray" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="填报开始日期" end-placeholder="填报结束日期" :picker-options="pickerOptions" class="input-with-select"
          style="width:400px;margin-left: 10px"></el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
          @click="saveJh">添加优秀事迹</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
          type="primary" plain style="margin-left: 10px;">迁移老版本</el-button>

        <el-button size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;" @click="exportXLS">导出</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small"  stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="stu_code" label="学号" width="135"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="100"></el-table-column>
      <el-table-column prop="class_name" label="班级名称" width="100"></el-table-column>
      <el-table-column prop="enter_name" label="实习单位" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column prop="head_teacher_name" label="班主任" min-width="100"></el-table-column>
      <el-table-column prop="instructor_name" label="指导老师" min-width="100"></el-table-column>
      <el-table-column prop="report_time" label="填报时间" min-width="120"></el-table-column>
      <el-table-column prop="create_name" label="创建人" width="150"></el-table-column>
      <el-table-column prop="create_date" label="创建时间" min-width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="success" size="mini" v-if="$store.state.teacher.id == scope.row.teacher_id"
            @click="viewInfo(scope.row)">编辑</el-tag>
          <el-tag class="pointer" type="primary" size="mini" v-else @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import util from "../../utils/util.js";
import {export_json_to_excel} from "../../../public/js/Export2Excel";
export default {
  components: {
    formdialog
  },
  data() {
    return {
      dateArray: [],
      thisTeacherid: 0,
      dialogshow: false,
      searchForm: {
        stu_str: "",
        teacher_str: "",
        class_name: "",
        sta_date: "",
        end_date: ""
      },
      EditItem: {
        is_draft: 1,
        title1: "",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        title: "",
        class_name: "",
        enter_name: "",
        instructor: "",
        instructor_name: "",
        head_teacher: 0,
        create_id: 0,
        head_teacher_name: "",
        event_desc: "",
        annex: [],
        report_time: "",
        create_name: "",
        sort: 99
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .post("/api/stu_event_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          stu_str: this.searchForm.stu_str,
          teacher_str: this.searchForm.teacher_str,
          class_name: this.searchForm.class_name,
          sta_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[0]) : "",
          end_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[1]) : "",
          biye: ""
        })
        .then(res => {
          res.data.data.map(a => {
            if (a.event_desc) {
              const text = this.HTMLPartToTextPart(a.event_desc);
              a.event_desc = text
            }
          })
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    HTMLPartToTextPart(HTMLPart) {
       let aa = HTMLPart
        // .replace(/\n/ig, '')
        .replace(/<style[^>]*>[\s\S]*?<\/style[^>]*>/ig, '')
        .replace(/<head[^>]*>[\s\S]*?<\/head[^>]*>/ig, '')
        .replace(/<script[^>]*>[\s\S]*?<\/script[^>]*>/ig, '')
        .replace(/<\/\s*(?:p|div)>/ig, '\n')
        .replace(/<br[^>]*\/?>/ig, '\n')
        .replace(/<[^>]*>/ig, '')
         .replace('&nbsp;', " ")
        .replace(/[^\S\r\n][^\S\r\n]+/ig, ' ')
      var mdash = "&nbsp;";
      aa = aa.replace(new RegExp(mdash, "g"), " ")
      return aa
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http.post("/api/stu_event_delete", { id: row.id }).then(res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
          });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.EditItem = {
        is_draft: 1,
        title1: "添加优秀实习生事迹",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        title: "",
        class_name: "",
        enter_name: "",
        instructor: "",
        instructor_name: "",
        head_teacher: "",
        create_id: 0,
        head_teacher_name: "",
        event_desc: "",
        annex: [],
        report_time: "",
        create_name: "",
        sort: 99
      };
      this.dialogshow = true;
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.EditItem.title1 = "优秀实习生事迹详情";
      this.dialogshow = true;
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_stu_event", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    exportXLS() {
      this.$http.post('/api/stu_event_list', {
        pagesize: 10000,
        stu_str: this.searchForm.stu_str,
        teacher_str: this.searchForm.teacher_str,
        class_name: this.searchForm.class_name,
        sta_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[0]) : "",
        end_date:
            this.dateArray.length > 0 ? util.formatDate(this.dateArray[1]) : ""
      }).then(res => {
        let data = res.data.data
        if (!data.length) {
          this.$message.warning('没有数据导出')
          return
        }
        let excelData = []
        data.map((item,index) => {
          excelData.push([
            ++index,
            item.stu_code,
            item.stu_name,
            item.class_name,
            item.enter_name,
            item.title,
            item.event_desc.replace(/<[^>]+>/g,''),
            item.head_teacher_name,
            item.instructor_name,
            item.create_name,
            item.create_date,
          ])
        })
        export_json_to_excel(['序号','学号','学生姓名','班级名称','实习单位','标题','内容','班主任','指导老师','创建人','填报时间'], excelData,'优秀实习生事迹导出')
      })
    },
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
