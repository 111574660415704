<template>
    <div class="bmList">
        <div class="bannerBg flexCenter" style="width:100%;height:200px">
            <img src="../../../public/img/h5/banner.png" alt="" style="width: 100%;display: block;">
        </div>

        <div class="flexStart" style="width:100%;border-bottom:2px solid #c0c4cc81;">
            <div class="flexCloumnCenter" style="width:120px;">
                <div class="" style="padding:10px 10px 6px 10px;font-weight:700;">捐赠项目</div>
                <div style="width:20px;height:4px;background-color:#ff8400a1;border-radius:2px;"></div>
            </div>
        </div>


        <!-- <van-loading v-if="showLoading" color="#0094ff" class="flexCenter" />
        <form action="/">
            <van-search v-if="!showLoading" v-model="searchValue" shape="round" background="#409eff"
                placeholder="搜一搜关键词（项目名称 / 团队名称）" @search="getList"
                style="position: fixed;top: 0;left: 0;width: 100%;z-index: 100;" />
        </form>
        <div v-if="!showLoading" class="" style="width: 100%;opacity: 0;">
            <van-search shape="round" background="#fff" placeholder="搜一搜关键词（项目名称 / 团队名称）" />
        </div> -->
        <div class="listBox" style="">
            <div class="listItem" @click="goDetail(item)" v-for="(item, index) in list" :key="index">
                <div class="flexStretch">
                    <div class="flexCenter" style="width:128px;height:98px;border-radius:4px;overflow:hidden;">
                        <img src="../../../public/img/h5/banner_02.jpeg" alt=""
                            style="min-width: 100%;min-height: 100%;object-fit: cover;">
                    </div>
                    <div class="flex_1 flexCloumn" style="margin-left:16px;justify-content: space-between;">
                        <div class="overMoreLine" style="width:100%;color: #303133;font-size: 16px;font-weight:700;">
                            南京市玄武职业教育66周年<br>暨南京市玄武中等专业学校成立20周年发展大会
                        </div>
                        <div class="flexBetween" style="width:100%;color: #909399;font-size: 14px;font-weight:700;">
                            <div><span style="color:#f56c6c;">{{ stasticObj.num || '*' }}</span>人已捐赠</div>
                            <div>已筹 <span style="color:#f56c6c;">{{ stasticObj.money || '*' }}</span>元</div>

                        </div>



                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
// import * as defaultEmpty from "../../public/lottie/defaultEmpty.json"
export default {
    data() {
        return {
            openid: '',
            list: [{}],
            searchValue: '',
            // defaultOptions: {
            //   animationData: defaultEmpty.default /*文件资源*/,
            // },
            color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
            showLoading: false,
            stasticObj: {},
            pageFrom: '',
            phone: ''
        }
    },
    mounted() {
        let aa = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx35fabc32ee24a50d&redirect_uri=http%3A%2F%2Fwxh5.aiwx.org.cn%2Fcode%3Fq%3D1%26uri%3Dhttp%253A%252F%252Fsx.qushixi.org.cn%252F%2523%252Fxwzz_donate_index&response_type=code&scope=snsapi_base#wechat_redirect`
        let aa1 = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx35fabc32ee24a50d&redirect_uri=http%3A%2F%2Fwxh5.aiwx.org.cn%2Fcode%3Fq%3D1%26uri%3Dhttp%253A%252F%252Fsx.qushixi.org.cn%252F%2523%252Fxwzz_donate_index%253Fpmid%253D209%2526from%253Dpage_h5&response_type=code&scope=snsapi_base#wechat_redirect`

        // https://sx.qushixi.org.cn/#/ems_postFilesRedirect
        document.title = "校友捐赠"
        this.openid = this.$route.query.openid
        this.getStastic()
        if (this.openid) {
            // this.getList('')
        } else {
            this.$message.warning('登录信息失效，请用微信扫码进入该页面')
        }
        if (this.$route.query.page) {
            this.pageFrom = this.$route.query.page
        }
        if (this.$route.query.phone) {
            this.phone = this.$route.query.phone
        }

    },
    updated() {
        document.scrollingElement.scrollTop = 0
    },

    methods: {
        getStastic() {
            this.$http.post(`/api/xwzz_by_donate_stastic`, {
                pmid: 209,
            }).then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.stasticObj = res.data[0]
                } else {
                    this.stasticObj = {}
                }
                this.showLoading = false

            })
        },
        getList(val) {
            this.showLoading = true
            this.list = []
            this.$http.post(`/api/project/list/${this.openid}`, {
                search: val,
                openid: this.openid,
                page: 1,
                pagesize: 10000,
            }).then(res => {
                if (res.data.data && res.data.data.items && res.data.data.items.length > 0) {
                    res.data.data.items.map(a => {
                        a.udate = a.udate.substring(0, 10)
                        a.teacherNames = a.teachers.map((n) => {
                            return n.name
                        }).join('，')
                    })
                    this.list = res.data.data.items
                }
                this.showLoading = false

            })
        },

        goDetail(row) {
            this.$router.push({
                path: '/xwzz_donate_detail',
                query: {
                    openid: this.openid,
                    id: row.id || 0,
                    page: this.pageFrom,
                    phone: this.phone,
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
.bannerBg {
    box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.1);
}

.bmList {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: cover;
    overflow: auto;
    background-color: #fff;

    .listBox {
        width: 100%;
        box-sizing: border-box;

        div {
            box-sizing: border-box;
        }

        .listItem {
            width: 100%;
            border-bottom: 1px solid #c0c4cc81;
            /*border-left: 3px solid #409eff;
            border-radius: 8px;*/
            //font-size: 14px;
            padding: 12px;
            position: relative;
            /*box-shadow: 1px -2px 1px hsla(220deg, 60%, 50%, 0.05),
                1px 1px 1px hsla(220deg, 60%, 50%, 0.05);*/
            //2px 4px 4px hsla(220deg, 60%, 50%, 0.2),
            //4px 8px 8px hsla(220deg, 60%, 50%, 0.2);
            margin-bottom: 20px;
        }
    }
}
</style>
