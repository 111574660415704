<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <tchtree v-model="searchForm.class_id"></tchtree>
        <el-date-picker @change="changeDate" size="small" style="width:180px;margin-right:10px" v-model="year_week"
                        type="week" format="yyyy 第 W 周" placeholder="选择周"></el-date-picker>
        <el-input placeholder="请输入关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
                  style="width:300px" clearable>
          <el-select v-model="searchForm.ktype" slot="prepend" placeholder="请选择" style="width:120px">
            <!-- <el-option label="企业名称" value="enter_name"></el-option> -->
            <el-option label="学生姓名" value="stu_name"></el-option>
          </el-select>
        </el-input>


        <el-select v-if="activeName === 'first'" v-model="searchForm.report_status" clearable placeholder="请选择"
                   size="small"
                   style="width:130px;margin-left:10px;margin-right:10px">
          <el-option label="全部" value=""></el-option>


          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核拒绝" :value="-1"></el-option>
          <el-option label="审核撤回" :value="2"></el-option>
        </el-select>
        <el-button icon="el-icon-search" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList()}">
          搜索
        </el-button>
        <el-button icon="el-icon-download"
                   style="margin-left: 10px;"
                   size="small"
                   type="primary"
                   plain @click="exportReport(1)">已写周报统计
        </el-button>
        <el-button icon="el-icon-download"
                   style="margin-left: 10px;"
                   size="small"
                   type="primary"
                   plain @click="exportReport(2)">未写周报统计
        </el-button>
        <el-button icon="el-icon-download"
                   style="margin-left: 10px;"
                   size="small"
                   type="primary"
                   plain @click="exportReport(3)">完成数量统计
        </el-button>
      </div>

      <div class="flexStart"
           style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary"
                   @click="tongbuJava">迁移老版本</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
    <template v-if="$store.state.pmid == 221">
      <el-tabs type="border-card" v-model="activeName" @tab-click="getList">
        <el-tab-pane label="已提交" name="first">
          <el-table :data="DataList" height="100%" size="small" stripe border
                    style="width:100%;" :row-class-name="tableRowClassName"
                    tooltip-effect="dark myTooltips">
            <el-table-column type="index" width="65" label="序号" align="center"></el-table-column>
            <el-table-column prop="sp_status" label="状态" width="90" align="center">
              <template slot-scope="scope">
                <el-tag size="mini" v-if="scope.row.report_status == 2" type="warning">审核撤回</el-tag>
                <el-tag size="mini" v-if="scope.row.report_status == -1" type="danger">审核拒绝</el-tag>
                <el-tag size="mini" v-if="scope.row.report_status == 1" type="success">审核通过</el-tag>
                <el-tag size="mini" v-if="scope.row.report_status == 0 && !scope.row.review_comment"
                        style="color: gray;">待审核
                </el-tag>
                <el-tag size="mini" v-if="scope.row.report_status == 0 && scope.row.review_comment"
                        style="color: goldenrod;">重新提交
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>
            <el-table-column prop="stu_name" label="学生姓名" width="120" align="center"></el-table-column>
            <el-table-column prop="class_name" label="所在班级" width="120" align="center"></el-table-column>
            <el-table-column prop="xibu_name" label="系部" width="150" align="center"></el-table-column>

            <el-table-column label="提交日期" prop="create_date" width="200" align="center">
              <template slot-scope="scope">
                {{ scope.row.udate || scope.row.create_date }}
              </template>
            </el-table-column>
            <el-table-column prop="create_date" label="周次" width="135" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.sp_chaoshi">
                  <el-tooltip :content="('超时审批，应该在' + scope.row.last_date + '之前审批')" placement="top">
                    <span style="color:#f56c6c;">{{ scope.row.report_year }}年第{{ scope.row.number }}周</span>
                  </el-tooltip>
                </div>
                <div v-else>{{ scope.row.report_year }}年第{{ scope.row.number }}周</div>
              </template>
            </el-table-column>

            <el-table-column prop="is_replenish" label="是否补报" width="100" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_replenish == 1" style="color: blue;">是</div>
                <div v-else>否</div>
              </template>
            </el-table-column>
            <el-table-column prop="review_name" label="审批人" width="100" align="center"/>
            <el-table-column prop="score" label="评分" width="80" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.score > 0">{{ getScoreTxt(scope.row.score) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="review_comment" label="审批评语" width="260" show-overflow-tooltip
                             align="center"></el-table-column>
            <el-table-column prop="review_time" label="审批时间" width="200" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.sp_chaoshi">
                  <el-tooltip :content="('超时审批，应该在' + scope.row.last_date + '之前审批')" placement="top">
                    <span style="color:#f56c6c;">{{ scope.row.review_time }}</span>
                  </el-tooltip>
                </div>
                <div v-else>{{ scope.row.review_time }}</div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="180" align="center">
              <template slot-scope="scope">
                <el-tag class="pointer" type="" size="mini" @click="viewInfo(scope.row,'show')">详情</el-tag>
                <template v-if="!isGradeLeader(scope.row.class_id)">
                  <el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
                          v-if="scope.row.report_status == 0" style="margin-left: 10px;">审批
                  </el-tag>
                  <el-tag class="pointer" type="warning" size="mini" @click="viewInfo(scope.row,'revoke')"
                          v-if="scope.row.report_status == 1" style="margin-left:10px;">撤回
                  </el-tag>
                  <el-tag class="pointer" v-if="showEditBtn" size="mini" style="margin-left: 10px;"
                          @click="viewInfo(scope.row,'edit')">编辑
                  </el-tag>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未提交" name="second">
          <el-table :data="DataList" height="100%" size="small" stripe border
                    style="width:100%;" :row-class-name="tableRowClassName"
                    tooltip-effect="dark myTooltips">
            <el-table-column type="index" width="65" label="序号" align="center"></el-table-column>
            <el-table-column prop="stu_code" label="学号" align="center"></el-table-column>
            <el-table-column prop="stu_name" label="学生姓名" align="center"></el-table-column>
            <el-table-column prop="class_name" label="所在班级" align="center"></el-table-column>
            <el-table-column prop="xibu_name" label="系部" align="center"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </template>
    <template v-else>
      <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border
                style="width:fit-content;width:100%;" :row-class-name="tableRowClassName"
                tooltip-effect="dark myTooltips">
        <el-table-column type="index" width="65" label="序号" align="center"></el-table-column>
        <el-table-column prop="sp_status" label="状态" width="90" align="center">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.report_status == 2" type="warning">审核撤回</el-tag>
            <el-tag size="mini" v-if="scope.row.report_status == -1" type="danger">审核拒绝</el-tag>
            <el-tag size="mini" v-if="scope.row.report_status == 1" type="success">审核通过</el-tag>
            <el-tag size="mini" v-if="scope.row.report_status == 0 && !scope.row.review_comment"
                    style="color: gray;">待审核
            </el-tag>
            <el-tag size="mini" v-if="scope.row.report_status == 0 && scope.row.review_comment"
                    style="color: goldenrod;">重新提交
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>
        <el-table-column prop="stu_name" label="学生姓名" width="120" align="center"></el-table-column>
        <el-table-column prop="class_name" label="所在班级" width="120" align="center"></el-table-column>
        <el-table-column prop="xibu_name" label="系部" width="150" align="center"></el-table-column>

        <el-table-column label="提交日期" prop="create_date" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.udate || scope.row.create_date }}
          </template>
        </el-table-column>
        <el-table-column prop="create_date" label="周次" width="135" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.sp_chaoshi">
              <el-tooltip :content="('超时审批，应该在' + scope.row.last_date + '之前审批')" placement="top">
                <span style="color:#f56c6c;">{{ scope.row.report_year }}年第{{ scope.row.number }}周</span>
              </el-tooltip>
            </div>
            <div v-else>{{ scope.row.report_year }}年第{{ scope.row.number }}周</div>
          </template>
        </el-table-column>

        <el-table-column prop="is_replenish" label="是否补报" width="100" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.is_replenish == 1" style="color: blue;">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column prop="review_name" label="审批人" width="100" align="center"/>
        <el-table-column prop="score" label="评分" width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.score > 0">{{ getScoreTxt(scope.row.score) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="review_comment" label="审批评语" width="260" show-overflow-tooltip
                         align="center"></el-table-column>
        <el-table-column prop="review_time" label="审批时间" width="200" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.sp_chaoshi">
              <el-tooltip :content="('超时审批，应该在' + scope.row.last_date + '之前审批')" placement="top">
                <span style="color:#f56c6c;">{{ scope.row.review_time }}</span>
              </el-tooltip>
            </div>
            <div v-else>{{ scope.row.review_time }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)"
                    v-if="scope.row.report_status == 0">审批
            </el-tag>

            <el-tag class="pointer" v-else type="" size="mini" @click="viewInfo(scope.row)">详情</el-tag>
            <el-tag class="pointer" type="warning" size="mini" @click="viewInfo(scope.row,'revoke')"
                    v-if="scope.row.report_status == 1" style="margin-left:10px;">撤回
            </el-tag>
            <el-tag class="pointer" v-if="showEditBtn" size="mini" style="margin-left: 10px;"
                    @click="viewInfo(scope.row,'edit')">编辑
            </el-tag>
            <el-tag class="pointer" type="danger" size="mini" style="margin-left: 10px;"
                    @click="handleDelete(scope.row)"
                    v-if="$store.state.pmid == 215">删除
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <formdialog v-if="dialogshow" :id="EditItem.id" :revoke="isRevoke" :isEdit="isEdit" :isView="isView"></formdialog>
  </div>
</template>

<script>

import tchtree from '../com/tchTree.vue'
import {mapGetters} from "vuex";
import {export_json_to_excel} from "../../../public/js/Export2Excel";

export default {
  components: {
    formdialog: () => {
      if (window.pmid == 226) {
        return import("./formdialogsp_medicalSchool.vue")
      } else {
        return import("./formdialogsp.vue")
      }
    },
    tchtree
  },
  data() {
    return {
      temphead: "//school.mmteck.cn/static/temp.png",

      dialogshow: false,
      isRevoke: false,
      year_week: "",
      report_year: "",
      week: "",
      weekStart: "",
      weekEnd: "",
      searchForm: {
        report_status: "",
        ktype: "stu_name",
        keyword: "",
        class_id: "",
        month: ""
      },
      EditItem: {
        id: 0,
        group_name: "",
        needpic: "",
        outside: "",
        latesign: ""
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      isEdit: false,
      showEditBtn: false,
      activeName: 'first',
      isView: false,
    };
  },
  filters: {
    handleDate(value) {	// value是输入框的内容，也是要显示的内容
      if (value) {
        return value.substring(0, 10)
      }
    }
  },
  mounted() {
    this.getNowDate();
    setTimeout(() => {
      if (this.$store.state.teacher.sys_roles && window.pmid == 214) {
        let roles = this.$store.state.teacher.sys_roles
        roles.filter(r => {
          if (r.role_code == 'xgzyb') {
            this.showEditBtn = true
          }
        })
      }
    }, 200)
  },
  computed: {
    ...mapGetters(['isGradeLeader']),
    showWeek() {
      return `${this.report_year} 第 ${this.week} 周`
    }
  },
  methods: {
    getNowDate() {
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year;
        this.week = d.data.week;
        this.year_week = new Date();
        this.weekStart = d.data.onMonday.split(" ")[0];
        this.weekEnd = d.data.sunday.split(" ")[0];

        this.getAllPickerVal();
        this.getOrgTree();
      });
    },
    changeDate(e) {
      if (e) {
        let str = this.$moment(this.year_week)
            .utcOffset(480)
            .format("yyyy-WW");
        this.weekStart = this.$moment(this.year_week)
            .utcOffset(480)
            .format("yyyy-MM-DD");
        this.weekEnd = this.$moment(this.year_week)
            .add(6, "day")
            .utcOffset(480)
            .format("yyyy-MM-DD");

        let arr = str.split("-");
        this.report_year = arr[0];
        this.week = arr[1];

        let strE = this.$moment(this.year_week)
          .utcOffset(480)
          .format("yyyy-MM-DD");
        let arrE = strE.split("-");
        if (Number(arr[1]) == 1) {
          if (arrE[0] == arr[0] && arrE[1] == 12) {
            //手动处理momentjs获取年份-周次时，跨年的年份问题
            this.report_year = Number(arr[0])+1;
          }
        }
        
        console.log(362, this.year_week, strE,this.report_year )
        /*let weekStart = this.$moment(e).format("YYYY-MM-DD")
        this.week = datejs.getWeekNumber(weekStart)
        this.report_year = this.$moment(weekStart).endOf('isoWeek').format('Y')*/
        this.getList();
      } else {
        this.report_year = "";
        this.week = "";
        this.getList();
      }
    },

    getOrgTree() {
      this.$http.post("/api/get_tch_cls_data").then(res => {
        res.data.list.map(e => {
          e.name = e.upname + e.name;
        });
        this.ClassList = res.data.list;
      });
    },
    getAllPickerVal() {
      //巡访形式&&巡访情况
      this.$http
          .post("/api/sys_param_list", {
            param_type: "'VISIT_FORM','VISIT_SITUATION'"
          })
          .then(res => {
            let listForm = [],
                listState = [];
            res.data.map((item, index, arr) => {
              if (item.param_type == "VISIT_FORM") {
                listForm.push(item);
              }
              if (item.param_type == "VISIT_SITUATION") {
                listState.push(item);
              }
            });

            this.listForm = listForm;
            this.listState = listState;
            this.getList();
          });
    },
    getList() {
      let url = this.activeName === 'first' ? '/api/report_approval_list' : '/api/report_approval_unsubmitted'
      this.DataList = []
      this.$http
          .post(url, {
            page: this.page.current_page,
            pagesize: this.page.per_page,
            report_year: this.report_year,
            number: this.week,
            report_type: 0,
            class_id: this.searchForm.class_id,
            ktype: this.searchForm.ktype,
            stu_name: this.searchForm.keyword,
            report_status: this.searchForm.report_status
          })
          .then(res => {
            // for(let item of res.data.data){
            // 	item.stu_list = JSON.parse(item.stu_list)
            // }
            this.DataList = res.data.data;
            this.page = res.data.page;
          });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    viewInfo(row, type) {
      this.isRevoke = type === 'revoke'
      this.isEdit = type === 'edit'
      this.EditItem = row;
      this.dialogshow = true;
      this.isView = type === 'show'
    },
    handleRevoke(row) {
      this.viewInfo(row)
      this.isRevoke = true
      this.isEdit = false
    },
    getScoreTxt(score) {
      let txt = "";

      switch (score) {
        case 1:
          txt = "不及格";
          break;
        case 2:
          txt = "及格";
          break;
        case 3:
          txt = "中等";
          break;
        case 4:
          txt = "良好";
          break;
        case 5:
          txt = "优秀";
          break;
      }
      return txt;
    },
    tableRowClassName({row, rowIndex}) {
      if (row.sp_chaoshi) {
        return "warning-row";
      } else {
        return "";
      }
    },
    handleEdit(row) {
      this.EditItem = row;
      this.dialogshow = true;
      this.isRevoke = false
      this.isEdit = true
    },
    handleDelete(row) {
      this.$confirm('此操作不可恢复，确认删除？', {
        type: "warning"
      }).then(() => {
        this.$http.post('/api/report_approval_delete', {id: row.id}).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
    exportReport(type) {
      let uri = ""
      let header = []
      if (type == 1) {
        uri = "/api/report_approval_list"
        header = ['序号', '状态', '学号', '姓名', '系部', '班级', '周次', '提交时间', '是否补报', '审批人', '评分', '审批评语', '审批时间']
      } else if (type == 2) {
        uri = "/api/report_approval_unsubmitted"
        header = ['序号', '学号', '姓名', '系部', '班级', '周次']
      } else if (type == 3) {
        header = ['序号', '学号', '姓名', '系部', '班级', '已写周报数量', '应写周报数量', '已写月报数量', '应写月报数量']
        uri = "/api/report_approval_count"
      }
      this.$http
          .post(uri, {
            page: 1,
            pagesize: 10000000,
            report_year: this.report_year,
            number: this.week,
            report_type: 0,
            class_id: this.searchForm.class_id,
            ktype: this.searchForm.ktype,
            stu_name: this.searchForm.keyword,
            report_status: this.searchForm.report_status
          })
          .then(res => {
            let data = res.data.data;
            let exportData = []
            let filename = ""
            data.map((d, index) => {
              if (type == 1) {
                filename = `${this.report_year}年第${this.week}周周报导出`
                exportData.push([
                  ++index,
                  d.report_status == 0 ? '待审核' : d.report_status == -1 ? '审核拒绝' : d.report_status == 1 ? '审核通过' : d.report_status == 2 ? '审核撤回' : '',
                  d.stu_code,
                  d.stu_name,
                  d.xibu_name,
                  d.class_name,
                  d.report_year + '年第' + d.number + '周',
                  d.udate,
                  d.is_replenish ? '是' : '否',
                  d.review_name,
                  d.score,
                  d.review_comment,
                  d.review_time,
                ])
              } else if (type == 2) {
                filename = `${this.report_year}年第${this.week}周未写周报名单导出`
                exportData.push([
                  ++index,
                  d.stu_code,
                  d.stu_name,
                  d.xibu_name,
                  d.class_name,
                  this.report_year + '年第' + this.week + '周'
                ])
              } else if (type == 3) {
                filename = "周月报完成情况统计导出"
                exportData.push([
                  ++index,
                  d.stu_code,
                  d.stu_name,
                  d.xibu_name,
                  d.class_name,
                  d.sxzbs,
                  d.yxzbs,
                  d.sxybs,
                  d.yxybs
                ])
              }
            })
            export_json_to_excel(header, exportData, filename)
          });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_report_approval", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
  }
};
</script>
<style>
.el-table .warning-row td {
  background-color: #f8ddc9 !important;
}

.myTooltips {
  max-width: 400px;
}
</style>
<style scoped>
::v-deep .el-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

::v-deep .el-tabs__content {
  flex: 1;
}

::v-deep .el-tab-pane {
  height: 100%;
}

.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
