<template>
  <el-dialog ref="ruleForm" :title="`实习${isMedicalSchool ? '医院' : '企业'}${formTitle}`" :visible.sync="isVisible"
    :close-on-click-modal="false" :modal-append-to-body="false" @close="handleClose" :destroy-on-close="true"
    custom-class="cus_dialog" width="1000px">

    <div class="dialog_right">
      <div class="flexStart"
        style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">

        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart required">{{ (isMedicalSchool ? '医院' : '企业') + '名称' }}</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.firm_name" placeholder="名称"></el-input>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">同步前</div>
          <div class="cell flexBetween flex_1">
            {{ formData.befor_name || '-' }}
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart required">组织代码</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.firm_num" placeholder="组织代码"></el-input>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">同步前</div>
          <div class="cell flexBetween flex_1">
            {{ formData.befor_code || '-' }}
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart">{{ (isMedicalSchool ? '医院' : '企业') + '法人' }}</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.legalPersonName" placeholder="法人"></el-input>

          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">{{ (isMedicalSchool ? '医院' : '企业') + '负责人' }}</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.firm_principal" placeholder="负责人"></el-input>

          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">联系电话</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.tel" placeholder="负责人联系电话"></el-input>

          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart required">所在地区</div>
          <div class="cell flexBetween flex_1">
            <el-cascader class="input_css" v-model="formData.area_id" ref="firstCascader" :options="cityOptions"
              :props="{ emitPath: false, checkStrictly: false }" :show-all-levels="false" placeholder="请选择所在地区"
              filterable></el-cascader>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">{{ (isMedicalSchool ? '医院' : '企业') + '地区' }}</div>
          <div class="cell flexBetween flex_1">
            {{ formData.apliceAreaName || "-" }}
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart required">详细地址</div>
          <div class="cell flexBetween flex_1">
            <el-input class="input_css" v-model="formData.address" placeholder="详细地址"></el-input>

          </div>
        </div>
        <div v-if="formData.tyc && formData.tyc.enter_type" class=" lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">{{ (isMedicalSchool ? '医院' : '企业') + '性质' }}</div>
          <div class="cell flexBetween flex_1">
            {{ formData.tyc ? formData.tyc.enter_type : "" }}
          </div>
        </div>
        <div v-else class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart">{{ (isMedicalSchool ? '医院' : '企业') + '性质' }}</div>
          <div class="cell flexBetween flex_1">
            <el-cascader class="input_css" v-model="formData.info" :options="qyxz"
              :props="{ value: 'param_value', label: 'param_desc', emitPath: false }"
              :placeholder="'请选择' + (isMedicalSchool ? '医院' : '企业') + '性质'"></el-cascader>
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart">国标行业</div>
          <div class="cell flexBetween flex_1">
            {{ formData.industryAll || "-" }}
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">注册资本</div>
          <div class="cell flexBetween flex_1">
            {{ formData.regCapital || "-" }}
          </div>
        </div>
        <div class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">人员规模</div>
          <div class="cell flexBetween flex_1">
            {{ formData.staffNumRange || "-" }}
          </div>
        </div>

        <div class="lineRow flexStretch" style="width:100%;">
          <div class="cell flexStart">是否校企合作</div>
          <div class="cell flexBetween flex_1">
            <el-select class="input_css" v-model="formData.is_cooperation"
              :placeholder="'是否是校企合作' + (isMedicalSchool ? '医院' : '企业')" style="width:200px;">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </div>
        </div>


        <div v-if="$store.state.pmid == 208" class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">学校指导老师</div>
          <div class="cell flexBetween flex_1">
            {{ formData.xnlxr || "-" }}
          </div>
        </div>

        <div v-if="$store.state.pmid == 208" class="lineRow flexStretch" style="width:50%;">
          <div class="cell flexStart">指导老师电话</div>
          <div class="cell flexBetween flex_1">
            {{ formData.xnlxr_tel || "-" }}
          </div>
        </div>
      </div>


    </div>

    <el-form v-if="false" :model="formData" label-width="120px" :rules="rules" ref="formData">
      <el-form-item label="组织代码" prop="firm_num">
        <el-input class="input_css" v-model="formData.firm_num"></el-input>
        <br /><span v-if="formData.befor_code" style="color:#909399;">同步前：{{ formData.befor_code }}</span>
      </el-form-item>
      <el-form-item :label="(isMedicalSchool ? '医院' : '企业') + '名称'" prop="firm_name">
        <el-input class="input_css" v-model="formData.firm_name"></el-input>
        <br /><span v-if="formData.befor_name" style="color:#909399;">同步前：{{ formData.befor_name }}</span>
      </el-form-item>
      <el-form-item :label="(isMedicalSchool ? '医院' : '企业') + '法人'" prop="firm_name">
        <el-input class="input_css" v-model="formData.legalPersonName"></el-input>
      </el-form-item>
      <el-form-item :label="(isMedicalSchool ? '医院' : '企业') + '负责人'" prop="firm_principal">
        <el-input class="input_css" v-model="formData.firm_principal"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input class="input_css" v-model="formData.tel"></el-input>
      </el-form-item>
      <el-form-item label="地区">
        <el-cascader class="input_css" v-model="formData.area_id" ref="firstCascader" :options="cityOptions"
          :props="{ emitPath: false, checkStrictly: false }" :show-all-levels="false" placeholder="请选择所在地区"
          filterable></el-cascader>
      </el-form-item>
      <el-form-item v-if="formData.apliceAreaName" :label="(isMedicalSchool ? '医院' : '企业') + '地区'">{{
        formData.apliceAreaName || "" }}</el-form-item>

      <el-form-item label="详细地址" prop="address">
        <el-input class="input_css" v-model="formData.address"></el-input>
      </el-form-item>
      <el-form-item v-if="formData.tyc && formData.tyc.enter_type" :label="(isMedicalSchool ? '医院' : '企业') + '性质'">{{
        formData.tyc ? formData.tyc.enter_type : "" }}</el-form-item>
      <el-form-item v-else :label="(isMedicalSchool ? '医院' : '企业') + '性质'">
        <el-cascader class="input_css" v-model="formData.info" :options="qyxz"
          :props="{ value: 'param_value', label: 'param_desc', emitPath: false }"
          :placeholder="'请选择' + (isMedicalSchool ? '医院' : '企业') + '性质'"></el-cascader>
      </el-form-item>
      <el-form-item v-if="formData.apliceAreaName" label="国标行业">{{
        formData.industryAll || "" }}</el-form-item>
      <el-form-item v-if="formData.regCapital" label="注册资本">{{
        formData.regCapital || "" }}</el-form-item>
      <el-form-item v-if="formData.staffNumRange" label="人员规模">{{
        formData.staffNumRange || "" }}</el-form-item>

      <el-form-item :label="'是否是合作' + (isMedicalSchool ? '医院' : '企业')">
        <el-select class="input_css" v-model="formData.is_cooperation"
          :placeholder="'是否是合作' + (isMedicalSchool ? '医院' : '企业')">
          <el-option label="是" :value="1"></el-option>
          <el-option label="否" :value="0"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="" slot="footer">
      <el-button @click="onSubmit('formData')" type="primary">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import citys from "@/utils/arae_value";

export default {
  props: {
    enterInfo: Object,
    dialogForm: Boolean,
    isMedicalSchool: Number,
    formTitle: String,
  },
  data() {
    var validateAreaId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择所在地区!"));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写" + (this.isMedicalSchool ? '医院' : '企业') + "地址!"));
      } else {
        callback();
      }
    };
    var validateFirmNum = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入组织机构代码"));
      } else {
        callback();
      }
    };
    var validateFirmName = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入" + (this.isMedicalSchool ? '医院' : '企业') + "名称"));
      } else {
        callback();
      }
    };
    var validateFirmPrincipal = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入" + (this.isMedicalSchool ? '医院' : '企业') + "负责人"));
      } else {
        callback();
      }
    };
    var validateTel = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入联系电话"));
      } else {
        callback();
      }
    };
    return {
      isVisible: true,
      formData: {},
      isok: [],
      qyxz: [],
      cityOptions: [],
      rules: {
        // area_id: [{ validator: validateAreaId, trigger: "blur" }],
        // address: [{ validator: validateAddress, trigger: "blur" }],
        firm_name: [{ required: true, message: "请填写" + (this.isMedicalSchool ? '医院' : '企业') + "地址!", trigger: 'blur' },],
        // firm_num: [{ validator: validateFirmNum, trigger: "blur" }],
        // firm_principal: [{ validator: validateFirmPrincipal, trigger: "blur" }],
        // tel: [{ validator: validateTel, trigger: "blur" }]
      },
    }
  },
  mounted() {
    let enterInfo = JSON.parse(JSON.stringify(this.enterInfo))
    if (enterInfo.tyc && enterInfo.tyc.search_json && enterInfo.tyc.search_json.legalPersonName) {
      enterInfo.legalPersonName = enterInfo.tyc.search_json.legalPersonName
    }
    this.formData = { ...enterInfo }
    console.log(133, this.formData)



    this.getComType().then(rr => {
      this.cityOptions = citys;
    })
  },
  watch: {

    // dialogForm(value) {
    //   this.isVisible = value
    // }
  },
  methods: {
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO','ISOK','SALARY_RANGE','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT'",
            used: 0
          })
          .then(res => {
            let qyxz = [];
            let isok = [];
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                item.param_value = Number(item.param_value)
                qyxz.push(item);
              }
              if (item.param_type == "ISOK") {
                item.param_value = Number(item.param_value)
                isok.push(item);
              }
            }
            this.qyxz = qyxz;
            this.isok = isok;
            resolve();
          });
      });
    },
    onSubmit(formName) {
      // this.$refs[formName].validate(valid => {
      // if (valid) {

      if (!this.formData.firm_name) {
        //this.formData.addnew = 1
        this.$message.warning('请填写单位名称~')
        return
      }
      if (!this.formData.firm_num) {
        //this.formData.addnew = 1
        this.$message.warning('请填写组织机构代码~')
        return
      }
      /*if (!this.formData.firm_principal) {
        //this.formData.addnew = 1
        this.$message.warning('请填写负责人~')
        return
      }*/
      /*if (!this.formData.tel) {
        this.formData.addnew = 1
        this.$message.warning('请填写联系电话~')
        return
      }*/

      if (!this.formData.area_id) {
        //this.formData.addnew = 1
        this.$message.warning('请填选择所在地区~')
        return
      }
      if (!this.formData.address) {
        //this.formData.addnew = 1
        this.$message.warning('请填写地址~')
        return
      }


      if (!this.formData.id) {
        this.formData.addnew = 1
      }
      this.$http.post("/api/updateEnterprise", this.formData).then(res => {
        this.$message.success("保存成功")
        this.$$parent(this, "getList");
        this.handleClose()
      });
      // } else {
      //   return false;
      // }
      // });
    },
    handleClose() {
      this.$emit('handleClose')
    },
  }
}
</script>


<style scoped lang="less">
/deep/ .el-dialog__body {
  /*padding: 0px 21px;*/
  color: #606266;
  font-size: 16px;
  word-break: break-all;
}

/deep/ .el-tabs__item {
  font-size: 18px;
}

/deep/.el-input__inner {
  font-size: 16px;
}

.input_css {
  width: 100%;
}

.dialog_right {
  .lineRow {
    .cell:first-of-type {
      width: 120px;
      padding-left: 8px;
    }

    .cell:last-of-type {
      color: #303133
    }
  }

  .imgaddbtn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dotted #ccc;
    font-size: 30px;
    cursor: pointer;
  }

  .imgremove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
  }

  .imgremove:hover {
    color: orange;
  }
}

.cusTabTabContainer {
  flex: 1;
  width: 100%;

  ::v-deep .el-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-tabs__content {
    flex: 1;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }
}
</style>
