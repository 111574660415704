<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <el-input :placeholder="(isMedicalSchool ? '医院' : '企业') + '名称'" v-model="searchForm.firm_name" size="small"
          class="input-with-select" style="width:150px;margin-left: 10px" clearable></el-input>
        <el-select v-model="searchForm.is_cooperation" clearable :placeholder="'是否合作' + (isMedicalSchool ? '医院' : '企业')"
          size="small" style="width:130px;margin-left:10px">
          <el-option :label="'是否合作' + (isMedicalSchool ? '医院' : '企业')" value></el-option>
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-select v-model="searchForm.biye" clearable placeholder="是否毕业" size="small"
          style="width:130px;margin-left:10px">
          <el-option label="是否毕业" value></el-option>
          <el-option label="未毕业" :value="0"></el-option>
          <el-option label="已毕业" :value="1"></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark"
          :content="'提示：查询未毕业实习生实习' + (isMedicalSchool ? '医院' : '企业') + '时，需选择年份联合查询，否则查询所有'" placement="top">
          <i class="el-icon-question" style="font-size: 20px;margin-left: 5px;"></i>
        </el-tooltip>
        <el-date-picker v-model="searchForm.sxYear" value-format="yyyy-MM-dd" size="small" type="year"
          :picker-options="pickerOptions" placeholder="选择实习年份" style="width:150px;margin-left: 10px"></el-date-picker>
        <el-date-picker v-model="searchForm.qyYear" value-format="yyyy-MM-dd" size="small" type="year"
          :picker-options="pickerOptions" :placeholder="(isMedicalSchool ? '医院' : '企业') + '入驻年份'"
          style="width:150px;margin-left: 10px"></el-date-picker>
        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList() }">搜索</el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary" @click="addNew">新增{{
          (isMedicalSchool ? '医院' : '企业') }}</el-button>

        <el-button v-if="isAdmin" icon="el-icon-download" size="small" type="primary" style="margin-left: 10px;"
          @click="handleDuplicate">重复{{ (isMedicalSchool ? '医院' :
          '企业')}}导出</el-button>
        <el-button v-if="isAdmin" icon="el-icon-refresh" size="small" type="primary" style="margin-left: 10px;"
          @click="batchtyc">批量同步天眼查</el-button>
        <el-button v-if="$store.state.pmid == 208" icon="el-icon-upload" style="margin-left: 10px;" size="small"
          type="primary" plain @click="importComShow_teacher = true">关联指导老师</el-button>
      </div>
      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button icon="el-icon-upload" style="margin-left: 10px;" size="small"
          @click="importComShow = true">导入</el-button>
        <el-button icon="el-icon-download" style="margin-left: 10px;" size="small"
          @click="showExportDialog = true">导出</el-button>
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" style="margin-left: 10px" size="small"
          type="primary" plain @click="tongbuJava">迁移老版本</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" v-if="qyxz.length > 0" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
      <el-table-column :label="(isMedicalSchool ? '医院' : '企业') + '名称'" prop="firm_name" show-overflow-tooltip
        width="260px" align="center">
        <template slot-scope="scope">
          <span style="cursor: pointer;" @click="updateTyc(scope.row)" title="同步天眼查"> <img
              :class="scope.row.tycid ? 'tyclogo1' : 'tyclogo0'" :src="'./img/tyclogo.png'" />{{ scope.row.firm_name
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="组织代码" prop="firm_num" show-overflow-tooltip width="130"></el-table-column>
      <el-table-column :label="(isMedicalSchool ? '医院' : '企业') + '负责人'" prop="firm_principal" width="150px"
        align="center"></el-table-column>
      <el-table-column label="联系电话" prop="tel" width="135" align="center"></el-table-column>
      <el-table-column label="实习人数" prop="sum_stu" width="100px" align="center"></el-table-column>
      <el-table-column :label="(isMedicalSchool ? '医院' : '企业') + '地址'" prop="address" width="160" show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column :label="(isMedicalSchool ? '医院' : '企业') + '性质'" prop="info" width="150px" align="center">
        <template slot-scope="scope">{{ scope.row.enter_type || (qyxz[scope.row.info] ? qyxz[scope.row.info].param_desc
          : "-") }}</template>
      </el-table-column>
      <el-table-column label="地区" prop="area_name" width="100" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column :label="'是否合作' + (isMedicalSchool ? '医院' : '企业')" prop="is_cooperation" width="130px"
        align="center">
        <template slot-scope="scope">{{ scope.row.is_cooperation == 1 ? "是" : "否" }}</template>
      </el-table-column>
      <el-table-column label="进驻方式" align="center">
        <template v-slot="scope">
          <el-tag v-if="scope.row.qyly == 1" size="mini">{{ (isMedicalSchool ? '医院' : '企业') }}申请</el-tag>
          <el-tag v-else-if="scope.row.qyly == 2" size="mini">学生选择</el-tag>
          <el-tag v-else size="mini">其他方式</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="进驻时间" align="center" prop="create_date"></el-table-column>
      <el-table-column fixed="right" label="操作" :width="(isMedicalSchool ? '200' : '140')" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="findStuList(scope.row)" type="success" size="mini">实习学生</el-tag>
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini"
            style="margin-left:10px;">修改</el-tag>
          <el-tag v-if="isAdmin&&isMedicalSchool" class="pointer" @click="updateFirmName(scope.row)" type="success"
            size="mini" style="margin-left:10px;">去括号</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <!-- 修改企业信息 -->
    <formdialog v-if="dialogForm" :formTitle="formTitle" :dialogForm="dialogForm" :enter-info="formData"
      :isMedicalSchool="isMedicalSchool" @handleClose="handleClose"></formdialog>
    <el-dialog ref="tablList" title="实习学生列表" :visible.sync="dialog1Form" :close-on-click-modal="false"
      :modal-append-to-body="false" :destroy-on-close="true" custom-class="cus_dialog">
      <el-table v-tableFit :data="tablList" row-key="id" height="50vh" size="small" stripe border style="width:100%;">
        <el-table-column label="学号" prop="stu_code" width="135" align="center"></el-table-column>
        <el-table-column label="学生姓名" prop="stu_name" width="100px" align="center"></el-table-column>
        <el-table-column label="班级名称" prop="class_name" width="150px" align="center"></el-table-column>
        <el-table-column label="联系方式" prop="phone" width="150px" align="center"></el-table-column>
        <el-table-column label="实习状态" prop="job" width="100px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.stu_enter_id == scope.row.sxid" style="color:blue">实习中</span>
            <span v-else style="color:orangered">已变更</span>

          </template>
        </el-table-column>
        <el-table-column label="岗位" prop="job" width="100px" align="center"></el-table-column>

        <el-table-column label="薪资范围" prop="salary" width="130" align="center">
          <template slot-scope="scope">{{ xzfw[scope.row.salary] ? xzfw[scope.row.salary].param_desc : "-" }}</template>
        </el-table-column>
        <el-table-column label="居住地址" prop="xjzdz" show-overflow-tooltip min-width="200px"
          align="center"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="'导入' + (isMedicalSchool ? '医院' : '企业')" :visible.sync="importComShow" width="800px"
      :close-on-click-modal="false" :destroy-on-close="true" custom-class="cus_dialog">
      <div style="text-align:center">
        <el-alert :title="(isMedicalSchool ? '医院' : '企业') + '名称为必填。地区，填写标准为：省、市、区/县，省市区之间用英文逗号隔开，如：江苏省,南京市,玄武区'"
          type="warning" show-icon :closable="false">
        </el-alert>
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="handleXlsUpload" :show-file-list="false" name="image">
          <el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
        </el-upload>
        <el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
          <a href="/xls/企业导入模板.xlsx" style="text-decoration: none;color:inherit">下载企业导入模板</a>
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :title="'导出' + (isMedicalSchool ? '医院' : '企业')" :visible.sync="showExportDialog" width="800px"
      :close-on-click-modal="false" :destroy-on-close="true" custom-class="cus_dialog">
      <el-alert title="选择要导出的字段，默认全部" :closable="false" show-icon>
      </el-alert>
      <div style="margin: 15px 0 0 20px;">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="fieldSelectList" @change="handleCheckedCitiesChange">
          <el-checkbox style="display: block;" v-for="(item, index) in filedList" :label="item.value" :key="index">{{
            item.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="" slot="footer">
        <el-button @click="exportComXls" type="primary">导出</el-button>
      </div>
    </el-dialog>

    <!-- 关联企业指导老师 -->
    <el-dialog title="关联企业指导老师" :visible.sync="importComShow_teacher" width="500px" :close-on-click-modal="false"
      :destroy-on-close="true" custom-class="cus_dialog">
      <div class="flexCloumn">
        <el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
          <a href="/xls/企业关联指导老师导入模板.xlsx" style="text-decoration: none;color:inherit">下载关联企业指导老师导入模板</a>
        </el-button>
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="handleXlsUpload_teacher" :show-file-list="false" name="image">
          <el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import citys from "../../utils/arae_value.js";
import util from "../../utils/util.js";
import { njsyxx_statistics_export_excel,export_json_to_excel } from "../../../public/js/Export2Excel";
export default {
  components: {
    formdialog: () => {
      if (window.pmid == 221) {
        return import('@/views/sxqy/wxjd/formdialog.vue')
      } else {
        return import('@/views/sxqy/formdialog.vue')
      }
    }
  },
  data() {
    var validateAreaId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择所在地区!"));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请填写" + (this.isMedicalSchool ? '医院' : '企业') + "地址!"));
      } else {
        callback();
      }
    };
    var validateFirmNum = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入组织机构代码"));
      } else {
        callback();
      }
    };
    var validateFirmName = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入" + (this.isMedicalSchool ? '医院' : '企业') + "名称"));
      } else {
        callback();
      }
    };
    var validateFirmPrincipal = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入" + (this.isMedicalSchool ? '医院' : '企业') + "企业负责人"));
      } else {
        callback();
      }
    };
    var validateTel = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入联系电话"));
      } else {
        callback();
      }
    };
    return {
      searchForm: {
        firm_name: "",
        is_cooperation: "",
        sxYear: util.formatDate(new Date()),
        biye: 0,
      },
      tableData: [],
      tablList: [],
      cityOptions: [],
      dialogForm: false,
      dialog1Form: false,
      showExportDialog: false,
      formData: {},
      formTitle: '',
      qyxz: [],
      isok: [],
      xzfw: [],
      xlyq: [],
      gzjy: [],
      wyyq: [],
      jsjyq: [],
      gwshzz: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      rules: {
        area_id: [{ validator: validateAreaId, trigger: "blur" }],
        address: [{ validator: validateAddress, trigger: "blur" }],
        firm_name: [{ validator: validateFirmName, trigger: "blur" }],
        firm_num: [{ validator: validateFirmNum, trigger: "blur" }],
        firm_principal: [{ validator: validateFirmPrincipal, trigger: "blur" }],
        tel: [{ validator: validateTel, trigger: "blur" }]
      },
      importComShow: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      filedList: [
        { name: "企业名称", value: "firm_name" },
        { name: "组织代码", value: "firm_num" },
        { name: "企业负责人", value: "firm_principal" },
        { name: "联系电话", value: "tel" },
        { name: "实习人数", value: "sum_stu" },
        { name: "地区", value: "area_name" },
        { name: "企业地址", value: "address" },
        { name: "企业性质", value: "enter_type" },
        { name: "是否合作企业", value: "is_cooperation" },
        { name: "单位属性", value: "unit_properties" },
        { name: "单位类型", value: "unit_type" },
        { name: "企业规模", value: "enterprise_size" },
        { name: "职工人数", value: "employees" },
        { name: "所属行业", value: "industry_name" },
        { name: "所属产业", value: "industry_type" },
        { name: "入驻方式", value: "qyly" },
        { name: "入驻时间", value: "create_date" },
      ],
      fieldSelectList: [],
      isIndeterminate: false,
      checkAll: false,
      isMedicalSchool: 0,
      importComShow_teacher: false,
    };
  },
  computed: {
    isAdmin() {
      console.log(290, this.$store.state.teacher.sys_roles)
      if (this.$store.state.teacher.sys_roles) {
        let roles = this.$store.state.teacher.sys_roles
        let arr = roles.filter(r => r.role_code.indexOf('admin') >= 0).map(r => r.role_code)
        return arr.length > 0
      }
    },
  },
  mounted() {
    this.getConfig()
    this.getComType().then(rr => {
      this.getList();
      this.cityOptions = citys;
    });
  },
  methods: {
    addNew() {
      this.formTitle = '新增'
      this.formData = {}
      this.dialogForm = true
    },
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", {
        keyword: "isMedicalSchool",
        ktype: "ktype"
      }).then(res => {
        for (let item of res.data.data) {
          this.isMedicalSchool = Number(item.kvalue)
          if (this.isMedicalSchool) {
            this.filedList = [
              { name: "医院名称", value: "firm_name" },
              { name: "组织代码", value: "firm_num" },
              { name: "医院", value: "firm_principal" },
              { name: "联系电话", value: "tel" },
              { name: "实习人数", value: "sum_stu" },
              { name: "地区", value: "area_name" },
              { name: "医院地址", value: "address" },
              { name: "医院性质", value: "enter_type" },
              { name: "是否合作医院", value: "is_cooperation" },
              { name: "单位属性", value: "unit_properties" },
              { name: "单位类型", value: "unit_type" },
              { name: "医院规模", value: "enterprise_size" },
              { name: "职工人数", value: "employees" },
              { name: "所属行业", value: "industry_name" },
              { name: "所属产业", value: "industry_type" },
              { name: "入驻方式", value: "qyly" },
              { name: "入驻时间", value: "create_date" },
            ]
          }
        }

      })
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO','ISOK','SALARY_RANGE','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT'",
            used: 0
          })
          .then(res => {
            let qyxz = [];
            let isok = [];
            let xzfw = []; //薪资类型
            let xlyq = []; //学历类型
            let gzjy = []; //工作经验
            let wyyq = []; //外语要求
            let jsjyq = []; //计算机要求
            let gwshzz = []; //计算机要求
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                item.param_value = Number(item.param_value)
                qyxz.push(item);
              }
              if (item.param_type == "ISOK") {
                item.param_value = Number(item.param_value)
                isok.push(item);
              }
              if (item.param_type == "SALARY_RANGE") {
                xzfw.push(item);
              }
              if (item.param_type == "EDUACTION") {
                xlyq.push(item);
              }
              if (item.param_type == "EXPERIENCE") {
                gzjy.push(item);
              }
              if (item.param_type == "ENGLISH_LEVEL") {
                wyyq.push(item);
              }
              if (item.param_type == "COMPUTER_LEVEL") {
                jsjyq.push(item);
              }
              if (item.param_type == "GWSHZT") {
                gwshzz.push(item);
              }
            }
            this.qyxz = qyxz;
            this.isok = isok;
            this.xzfw = xzfw;
            this.xlyq = xlyq;
            this.gzjy = gzjy;
            this.wyyq = wyyq;
            this.jsjyq = jsjyq;
            this.gwshzz = gwshzz;
            resolve();
          });
      });
    },
    getList() {
      let data = {};
      data.firm_name = this.searchForm.firm_name;
      data.sxYear = this.searchForm.sxYear;
      data.qyYear = this.searchForm.qyYear
      data.is_cooperation = this.searchForm.is_cooperation;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.biye = this.searchForm.biye
      data.is_sxqy = 1
      this.$http.post("/api/enterprise_list", data).then(res => {
        this.tableData = res.data.data;
        this.tableData.map(a => {
          let tyc = a.tyc_json ? JSON.parse(a.tyc_json) : {}
          if (!a.address) {
            a.address = tyc.regLocation || ""
          }
          if (a.area_id) {
            let obj = this.getParentId(citys, a.area_id);
            if (obj) {
              a.area_name = obj.label;
            } else {
              a.area_name = "";
            }
          }
          if (!a.area_name) {
            a.area_name = tyc.city ? (tyc.city + tyc.district) : ""
          }
          a.info = a.info >= 0 ? a.info : '';
        });
        this.page = res.data.page;
      });
    },
    findStuList(row) {
      this.$http
        .post("/api/stu_enter_list", {
          enter_id: row.id,
          sxYear: this.searchForm.sxYear,
          biye: this.searchForm.biye,
        })
        .then(res => {
          this.tablList = res.data;
          this.dialog1Form = true;
        });
    },
    getParentId(list, iid) {
      if (list && list.length > 0) {
        for (let o of list) {
          if (o.value == iid) return o;
          const o_ = this.getParentId(o.children, iid);
          if (o_) return o_;
        }
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.formData.id) {
            this.formData.addnew = 1
          }
          this.$http.post("/api/updateEnterprise", this.formData).then(res => {
            this.dialogForm = false;
            this.$message.success("保存成功")
            this.getList();
          });
        } else {
          return false;
        }
      });
    },
    handleModify(row) {
      this.formTitle = '修改'
      this.$http.post("/api/enterprise_info", { id: row.id }).then(res => {
        this.formData = res.data; let tycInfo = {}
        if (res.data.tyc) {
          if (res.data.tyc.search_json && this.isJSON(res.data.tyc.search_json)) {
            res.data.tyc.search_json = JSON.parse(res.data.tyc.search_json)
            if (res.data.tyc.search_json.regCapital) {
              this.formData.regCapital = res.data.tyc.search_json.regCapital
            }
          }
          tycInfo = res.data.tyc
          if (res.data.tyc.tyc_json) {
            // console.log()
            if (res.data.tyc.tyc_json.city && res.data.tyc.tyc_json.district) {
            this.formData.apliceAreaName = res.data.tyc.tyc_json.city + res.data.tyc.tyc_json.district
            }
            if (res.data.tyc.tyc_json.industryAll && res.data.tyc.tyc_json.industryAll.category) {
              this.formData.industryAll = `${res.data.tyc.tyc_json.industryAll.category}${res.data.tyc.tyc_json.industryAll.categoryBig ? ' > ' : ''}
              ${res.data.tyc.tyc_json.industryAll.categoryBig}${res.data.tyc.tyc_json.industryAll.categoryMiddle ? ' > ' : ''}
              ${res.data.tyc.tyc_json.industryAll.categoryMiddle}${res.data.tyc.tyc_json.industryAll.categorySmall ? ' > ' : ''}
              ${res.data.tyc.tyc_json.industryAll.categorySmall}`
            }

           
            if (res.data.tyc.tyc_json.staffNumRange) {
              this.formData.staffNumRange = res.data.tyc.tyc_json.staffNumRange
            }
            if (res.data.tyc.tyc_json.industry) {
              this.formData.industry = res.data.tyc.tyc_json.industry
            }
            if (res.data.tyc.tyc_json.regCapital) {
              this.formData.regCapital = res.data.tyc.tyc_json.regCapital
            }

            //     {
            //   "staffNumRange": "小于50人",
            //     "city": "南京市",
            //     "district": "六合区", "
            //       "regLocation": "南京市六合区马鞍街道汪杨98号1、3栋",
            //     "legalPersonName": "庞梁国",
            //     "companyOrgType": "有限责任公司(自然人投资或控股的法人独资)",
            //     "industry": "卫生",

            //     "name": "南京荟宁医院有限公司",

            // }
          }
          console.log(366, tycInfo)
        } 
        // if (res.data && res.data.area_id) {
        //   let obj = this.getParentId(citys, res.data.area_id);
        //   this.formData.area_name = obj.label;
        // }
        //this.formData.is_cooperation = res.data.is_cooperation !== null ? String(res.data.is_cooperation) : null;
        //this.formData.info = res.data.info !== null ? String(res.data.info) : null
        if (!this.formData.firm_principal) {
          this.formData.firm_principal = tycInfo.tyc_json ? tycInfo.tyc_json.legalPersonName : ""
        }
            if (!this.formData.address) {
          this.formData.address = tycInfo.tyc_json ? tycInfo.tyc_json.regLocation : ""
        }

        
        // if (!this.formData.info) {
        //   this.formData.info = tycInfo.tyc_json.companyOrgType
        // }
        // console.log(366, this.formData.info)
        this.dialogForm = true;
        this.$forceUpdate()
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_enterprise", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    updateTyc(e) {
      this.$http.post("/api/enter_tyc_update", { firm_name: e.firm_name }).then(res => {
        this.$message.success("更新成功")
        this.getList()
      })
    },
    updateFirmName(e) {
      let firm_name = e.firm_name
      let arr1 = firm_name.split('）')
      if (arr1 && arr1.length > 1 && arr1[1]!='') {
        console.log(537, arr1)
        this.$confirm("该括号内容可能是必要内容，是否确定去除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$http.post("/api/del_ent_bracket", { id: e.id ? e.id : '' }).then(res => {
            this.$message.success(res.data.msg)
            this.getList()
          })
        })
      } else {
        this.$http.post("/api/del_ent_bracket", { id: e.id ? e.id : '' }).then(res => {
          this.$message.success(res.data.msg)
          this.getList()
        })
      }
    },
    handleXlsUpload(e) {
      this.$http.post("/api/enterprise_import", { url: e.src }).then(res => {
        let error = res.data.error
        if (error.length) {
          let _html = "<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
          for (let value of error) {
            _html += `<p style="line-height: 30px;">${value}</p>`
          }
          _html += '</div>'
          this.$alert(_html, '导入失败' + (this.isMedicalSchool ? '医院' : '企业'), {
            dangerouslyUseHTMLString: true,
          })
        } else {
          this.$message.success("导入成功");
        }
        this.getList();
        this.importComShow = false;
      });
    },

    handleXlsUpload_teacher(e) {
      this.$http.post("/api/enterprise_teacher_relative_import", { url: e.src }).then(res => {
        let error = res.data.error
        if (error.length) {
          let _html = "<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
          for (let value of error) {
            _html += `<p style="line-height: 30px;">${value}</p>`
          }
          _html += '</div>'
          this.$alert(_html, '导入失败', {
            dangerouslyUseHTMLString: true,
          })
        } else {
          this.$message.success("导入成功");
        }
        this.getList();
        this.importComShow_teacher = false;
      });
    },
    exportComXls() {
      let data = {};
      data.firm_name = this.searchForm.firm_name;
      data.sxYear = this.searchForm.sxYear;
      data.qyYear = this.searchForm.qyYear
      data.is_cooperation = this.searchForm.is_cooperation;
      data.biye = this.searchForm.biye
      data.is_sxqy = 1
      data.page = 1;
      data.pagesize = 100000;
      this.$http.post('/api/enterprise_list', data).then(res => {
        let titles = []
        let fields = this.fieldSelectList
        if (fields.length > 0) {
          let newList = this.filedList.filter(r => fields.includes(r.value))
          titles = newList.map(m => m.name)
          fields = newList.map(m => m.value)
        } else {
          titles = this.filedList.map(f => f.name)
          fields = this.filedList.map(f => f.value)
        }
        titles.unshift('序号')
        let excelData = [
          [`${data.sxYear.substring(0, 4)}实习${(this.isMedicalSchool ? '医院' : '企业')}信息`],
          titles,
        ]
        res.data.data && res.data.data.map((r, index) => {
          ++index
          let areaNames = ""
          if (r.area_id) {
            areaNames = this.getParent(citys, r.area_id)
            if (areaNames && areaNames.length) {
              areaNames = areaNames.map(a => a.label).reverse().join(',')
            }
          }
          let item = [index]
          for (let i = 0; i < fields.length; i++) {
            let field = fields[i]
            if (field == 'area_name') {
              item.push(areaNames)
              continue
            }
            if (field == 'enter_type') {
              item.push(r.enter_type ?? r.unit_nature ?? "")
              continue
            }
            if (field == 'is_cooperation') {
              item.push(r.is_cooperation ? "是" : "否")
              continue
            }
            if (field == 'qyly') {
              item.push(r.qyly == 1 ? ((this.isMedicalSchool ? '医院' : '企业') + '申请') : r.qyly == 2 ? '学生选择' : '其他方式')
              continue
            }
            item.push(r[field] || "")
          }
          excelData.push(item)
        })
        njsyxx_statistics_export_excel(excelData, titles.length)
        this.showExportDialog = false
        this.isIndeterminate = false
        this.fieldSelectList = []
      })
    },
    getParent(list, id) {
      for (let i in list) {
        if (list[i].value === id) {
          return [list[i]]
        }
        if (list[i].children != null) {
          let node = this.getParent(list[i].children, id)
          if (node !== undefined) {
            return node.concat(list[i])
          }
        }
      }
    },
    handleDuplicate() {
      this.$http.post('/api/enterprise_duplicate_list').then(res => {
        if (!res.data.length) {
          this.$message.error('没有重复数据')
          return
        }
        let excelData = []
        res.data.forEach((d,i) => {
          excelData.push([
              i+1,
              d.firm_num,
              d.firm_name
          ])
        })
        export_json_to_excel(['ID','组织代码',`${this.isMedicalSchool ? '医院' : '企业'}名称`],excelData,`重复${this.isMedicalSchool ? '医院' : '企业'}导出`)
      })
      /*this.$confirm(`确定去重，去重之后相同${(this.isMedicalSchool ? '医院' : '企业')}的实习生将合并到一个${(this.isMedicalSchool ? '医院' : '企业')}里面，相同${(this.isMedicalSchool ? '医院' : '企业')}只保留一个`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/api/enterprise_rm_duplicate', {}).then(res => {
          this.$message.success('操作成功，等待1分钟再刷新查看')
          this.getList()
        })
      })*/
    },
    handleClose() {
      this.dialogForm = false
    },
    batchtyc() {
      this.$http.post("/api/enter_tyc_update", {}).then(res => {
        this.$message.success("后台批量更新中，请稍后刷新查看")
        this.getList()
      })
    },
    handleCheckAllChange(val) {
      this.fieldSelectList = val ? this.filedList.map(f => f.value) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.filedList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filedList.length;
    }
  }
};
</script>
<style lang="less" type="text/less" scoped>
.input_css {
  width: 430px;
}

.tyclogo0 {
  width: 15px;
  height: 15px;
  filter: grayscale(100%);
}

.tyclogo1 {
  width: 15px;
  height: 15px;
}</style>
