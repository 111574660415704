import { render, staticRenderFns } from "./dkyj.vue?vue&type=template&id=7b2f3e9f&scoped=true"
import script from "./dkyj.vue?vue&type=script&lang=js"
export * from "./dkyj.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b2f3e9f",
  null
  
)

export default component.exports