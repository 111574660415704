<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" style="">
          <tchtree ref="tchtree" v-model="queryForm.class_ids" @change="getList" @changeType="changeType"
            style="margin: 0 10px 10px 0;"></tchtree>
          <el-form-item label="" min-width="120">
            <el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>


          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getList">搜索</el-button>


            <!--                        <el-upload style="display: inline-block; margin-left: 10px;" action="" :multiple="true"
                                        :http-request="uploadOss" :on-success="handleFileUpload" :on-progress="handleProcess"
                                        :show-file-list="false" name="image">
                                        <el-button size="small" type="primary" icon="el-icon-upload">上传</el-button>
                                    </el-upload>-->
            <el-button size="small" type="primary" icon="el-icon-download" plain @click="downloadFile">下载</el-button>
            <el-button size="small" type="warning" icon="el-icon-refresh" plain @click="reloadFile">生成当月数据</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>数据上报</el-breadcrumb-item>
          <el-breadcrumb-item>{{ year_month + '月' }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div style="height:4px">
        <div id="processbox" style="height:4px;border-radius: 2px; background-color: green; width: 0px;"></div>
      </div>
      <el-card>


        <div style="display:flex">
          <div class="nextpath" @click="goPath(0)">根目录</div>
          <div class="nextpath" @click="goPath(p.id)" v-for="(p, i) in pathList" :key="i"><i
              class="el-icon-arrow-right"></i>{{ p.filename }}
          </div>
        </div>


        <el-table v-tableFit stripe :data="DataList"
          style="width: 100%;margin-bottom: 20px; margin-top: 5px; color: #000;" border size="mini">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="cover" label="类型" width="60">
            <template slot-scope="scope">
              <div style="text-align:center">

                <img v-if="scope.row.ftype == 'file'" :src="'./img/icon/' + scope.row.filetype + '.png?'"
                  class="typeicon" />
                <img v-if="scope.row.ftype == 'folder'" :src="'./img/icon/folder.png'" class="typeicon" />


              </div>

            </template>
          </el-table-column>
          <el-table-column prop="filename" label="文件名称" min-width="230">
            <template slot-scope="scope">
              <div class="filenameitem">
                <div @click.stop="viewInfo(scope.row)">
                  <span>{{ scope.row.filename }}</span>
                  <span v-if="scope.row.report_type != 'sxqkhz'  && scope.row.finished"
                    style="color:#409eff;">&nbsp;(已完成)</span>
                  <span v-if="scope.row.ftype=='folder' && !scope.row.finished"
                    style="color:#f56c6c;">&nbsp;(未完成)</span>
                  <span v-if="scope.row.report_type == 'sxqkhz' && scope.row.pid && scope.row.finished"
                    @click.stop="onModify(scope.row)" style="color:#409eff;">&nbsp;(已完成,点击修改)</span>
                  <span v-if="scope.row.report_type == 'sxqkhz' && scope.row.pid && !scope.row.finished"
                    @click.stop="onModify(scope.row)" style="color:#f56c6c;">&nbsp;(未完成，前往完成)</span>
                  <span v-if="scope.row.report_type == 'case' && scope.row.pid && !scope.row.finished" @click="linkC"
                    style="color:#f56c6c;">&nbsp;(未完成，前往完成)</span>
                  <span v-if="scope.row.report_type == 'event' && scope.row.pid && !scope.row.finished" @click="linkD"
                    style="color:#f56c6c;">&nbsp;(未完成，前往完成)</span>
                  <span
                    v-if="(scope.row.report_type == 'zf-E' || scope.row.report_type == 'zf-F') && scope.row.pid && !scope.row.finished"
                    @click="linkE" style="color:#f56c6c;">&nbsp;(未完成，前往完成)</span>

                  <span v-if="scope.row.report_type == 'lg' && scope.row.pid && !scope.row.finished"
                    style="color:#f56c6c;">&nbsp;(暂无离岗申请数据)</span>
                </div>
                <div v-if="scope.row.pid && scope.row.url" class=" fileicon">
                  <i class="el-icon-download" title="下载" @click.stop="openFile(scope.row)"></i>
                </div>
              </div>

            </template>
          </el-table-column>

          <!-- <el-table-column prop="id" label="上传日期" width="180">
            <template slot-scope="scope">
              {{ scope.row.cdate }}

            </template>
          </el-table-column> -->

          <el-table-column prop="filename" label="文件大小" width="120">
            <template slot-scope="scope">
              {{ getSize(scope.row.filesize) }}

            </template>
          </el-table-column>
          <!-- <el-table-column prop="filename" label="上传用户" width="120">
            <template slot-scope="scope">
              <img :src="scope.row.tch_avatar || scope.row.stu_avatar" class="headimg" />
              <span style="vertical-align:middle;line-height: 32px; margin-left: 5px; color: #888;">{{
                scope.row.username || scope.row.stu_name }}</span>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
          :current-page="Page.current_page" :page-size="Page.per_page" :page-sizes="[10, 20, 50, 100]"
          layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
        </el-pagination>

      </el-card>
    </div>


    <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="dialogshow = false" width="800px" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">上传文件</span>
      </div>
      <div>

        <el-form :model="formData" ref="EditItem" label-width="120px">
          <el-form-item label="发送对象">

            <el-button size="small" v-if="!sendToAll" style="margin-right: 20px;" @click="dialogVisible = true">
              {{ choosedTeachers > 0 ? '教师' + choosedTeachers + '人' : '' }}
              {{ choosedClasses > 0 ? '班级' + choosedClasses + '个' : '' }}
              {{ choosedClasses == 0 && choosedTeachers == 0 ? '手动选择' : '' }}
            </el-button>

            <el-checkbox v-model="sendToAll">全部人员</el-checkbox>

            <el-checkbox v-model="allTeacher">对所有内部教职工</el-checkbox>

            <el-checkbox v-model="allStudent">对所有学生</el-checkbox>

          </el-form-item>
          <el-form-item label="选择文件">
            <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss"
              :on-progress="handleProcess" :on-success="uploadFiles" :show-file-list="false" multiple>
              <el-button type="primary" size="small">点击上传</el-button>
            </el-upload>
            <ul v-if="formData.files && formData.files.length" class="el-upload-list el-upload-list--text"
              style="width: 320px;">
              <li class="el-upload-list__item is-success" v-for="(file, index) in formData.files" :key="index">
                <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{ file.fileName }}</a>
                <label class="el-upload-list__item-status-label"><i
                    class="el-icon-upload-success el-icon-circle-check"></i></label>
                <i class="el-icon-close" @click="handleRemoveFile(index)"></i>
              </li>
            </ul>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogshow = false">取消</el-button>
        <el-button type="primary" @click="saveForm">保存</el-button>
      </div>
    </el-dialog>

    <deptuser v-if="dialogVisible" :oteachers="formData.target_teachers" :oclasses="formData.target_classes"></deptuser>


    <formdialog v-if="dialogshow1" :params="params_dlg"></formdialog>

    <!---下载进度--->
    <el-dialog title="下载进度" :visible.sync="showProgress" width="800px" :close-on-click-modal="false">
      <el-progress :text-inside="true" :stroke-width="20" :percentage="progressPercent" :status="progressStatus"></el-progress>
      <span>{{progressMsg}}</span>
    </el-dialog>
  </div>
</template>

<script>

import deptuser from "@/views/com/deptuser/deptuser.vue";
import formdialog from "./formdialog.vue"
import tchtree from "@/views/com/tchTree.vue";

export default {
  components: { deptuser, formdialog, tchtree },
  data() {
    return {
      queryForm: {
        keyword: ""
      },
      pid: 0,
      teacherid: 0,
      DataList: [],
      Page: {},

      pathList: [],
      dialogshow: false,
      formData: {
        target_teachers: "", //部门教师
        target_classes: "", //班级
        files: [],
      },
      allStudent: false,
      allTeacher: false,
      sendToAll: false, //发送给所有用户
      dialogVisible: false,
      choosedTeachers: 0,
      choosedClasses: 0,
      year_month: "",
      deptType: 'xx',
      dialogshow1: false,
      EditItem: {
        id: 0,
        group_name: "",
        needpic: "",
        outside: "",
        latesign: "",
      },
      params_dlg: {},
      router_class_id: '',
      showProgress: false,
      progressPercent: 0,
      progressMsg: '',
      progressStatus: 'success',
      timer: null,
    }
  },
  mounted() {
    this.year_month = this.$route.params.month
    // console.log(227, this.$route.query)
    if (this.$route.query.dir) {
      this.pid = this.$route.params.dir
    }
    if (this.$route.query.class_id) {
      this.router_class_id = this.$route.query.class_id
    }
    this.$http.post("/api/get_teacher_info").then(res => {
      this.teacherid = res.data.id
      this.getList()
    })

  },
  watch: {
    $route: function (n, o) {
      let _this = this
      console.log('240$route',n)
      let dir = n.query.dir
      let router_class_id = n.query.class_id
      this.Page.current_page = 1
      if (dir) {
        dir = dir.split(',')

        let pid = dir[dir.length - 1];
        let isback = false;
        for (let i = 0; i < this.pathList.length; i++) {
          if (this.pathList[i].id == pid && i != this.pathList.length - 1) {
            console.log(i, pid, this.pathList.length)
            this.pathList.splice(i, 1)
            isback = true
            break
          }
        }
        console.log(isback)
        if (isback) {
          this.router_class_id = router_class_id
          this.pid = pid
          this.getList()
        }
        if (router_class_id) {
          this.router_class_id = router_class_id
          _this.reloadApi().then(_ => {
            setTimeout(() => {
              _this.getList()
            }, 500)
          })
        }

      } else {
        this.pathList = []
        this.pid = 0
        this.getList()
      }


    }
  },
  beforeRouteUpdate(to, from, next) {
    this.queryForm.keyword = ""
    this.class_id = ""
    this.year_month = to.params.month
    this.Page.current_page = 1
    this.$refs.tchtree.clearEmpty()
    next()
  },
  methods: {

    getSize(size) {
      if (size) {


        if (size < 1024) {
          return size + "B"
        } else if (size >= 1024 && size < 1024 * 1024) {
          return parseInt(size / (1024) * 10) / 10 + "KB"
        } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024) * 10) / 10 + "MB"
        } else if (size >= 1024 * 1024 * 1024 && size < 1024 * 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024 * 1024) * 10) / 10 + "GB"
        }
      } else {
        return "-"
      }
    },


    getList() {
      let arr = this.year_month ? this.year_month.split('-') : []
      let params = {
        page: this.Page.current_page,
        pagesize: this.Page.per_page,
        keyword: this.queryForm.keyword,
        report_year: arr.length ? arr[0] : '',
        report_month: arr.length ? arr[1] : '',
        class_ids: this.queryForm.class_ids,
      }
      if (this.pid) {
        params.pid = this.pid
      }
      this.$http.post("/api/month_report_list", params).then(res => {
        res.data.data.map(a => {
          if (a.report_type&&a.report_type.indexOf('lg')>-1) {
            a.report_type = 'lg'
          }
        })
        this.DataList = res.data.data
        this.Page = res.data.page
      })
    },
    addFolder() {
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value.trim()) {
          this.$http.post("/api/t_sch_filesstore_edit",
            {
              pid: this.pid,
              ftype: "folder",
              filename: value.trim(),
              filesize: 0,
              filetype: '',
              url: '',
              is_share: 1
            }).then(res => {
              this.$message.success("上传成功")
              this.getList()
            })
        }
      }).catch(() => {

      });

    },
    search() {
      this.Page.current_page = 1
      this.getList()
    },
    handleAdd() {
      this.dialogshow = true
      this.formData.files = []
      this.formData.target_teachers = ""
      this.formData.target_classes = ""
      this.choosedClasses = 0
      this.choosedTeachers = 0
      this.allStudent = false
      this.allTeacher = false
      this.sendToAll = false
    },
    handleProcess(e) {

      let p = e.loaded / e.total * 100
      $("#processbox").show().css("width", p + "%")
    },
    handleFileUpload(e) {

      let filename = e.fileName
      let filetype = e.fileName.substr(e.fileName.lastIndexOf(".") + 1)
      console.log(filename, filetype)
      $("#processbox").hide()
      this.$http.post("/api/t_sch_filesstore_edit",
        {
          pid: this.pid,
          ftype: "file",
          filetype: filetype,
          filename: filename,
          filesize: e.size,
          url: e.src,
          is_share: 1
        }).then(res => {
          this.$message.success("上传成功")
          this.getList()
        })
    },
    viewInfo(e) {

      if (e.ftype == "folder") {
        this.pid = e.id;
        this.pathList.push(e)
        // this.getList()
        window.location.href = "/#" + "/month_data_report/" + this.year_month + "?dir=" + encodeURIComponent(this.pathList.map(a => {
          return a.id
        }).join(",")) + "&class_id=" + e.class_id
        // this.$router.push("/files?dir="+encodeURIComponent(this.pathList.map(a=>{return a.id}).join(",")))
      } else {
        if (e.url) {
          this.openFile(e)
        }
      }
    },
    goPath(id) {
      if (id == 0) {
        this.pathList = [];

      } else {
        for (let i = 0; i < this.pathList.length; i++) {
          if (this.pathList[i].id == id) {
            this.pathList.splice(i + 1)

            break
          }
        }
      }
      this.pid = id
      this.getList()
    },
    openFile(e) {
      if (e.url) {
        //window.open(e.url, "_blank")
        this.$http.get(e.url, {
          responseType: 'arraybuffer'
        }).then(r => {
          var blob = new Blob([r.data], { type: 'application/octet-stream;charset=UTF-8' })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.style.display = 'none'
          downloadElement.href = href
          downloadElement.download = decodeURIComponent(e.filename) // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href)
        })
      }
    },
    deleteFile(e) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/t_sch_filesstore_delete", {
          id: e.id
        }).then(res => {
          if (res.data.affectedRows) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败，没有权限!'
            });
          }

        })

      }).catch(() => {

      });
    },
    renameFile(e) {
      this.$prompt('请输入新的文件名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value.trim()) {
          e.filename = value.trim()
          this.$http.post("/api/t_sch_filesstore_edit", e).then(res => {
            this.$message.success("修改成功")
            this.getList()
          })
        }
      }).catch(() => {

      });
    },
    uploadFiles(e) {
      $("#processbox").hide()
      e.filetype = e.fileName.substr(e.fileName.lastIndexOf(".") + 1)
      e.is_share = 1
      this.formData.files.push(e)
    },
    handleRemoveFile(idx) {
      this.formData.files.splice(idx, 1)
    },
    setTarget(e) {
      if (e.teachers && e.teachers.length > 0) {
        this.choosedTeachers = e.teachers.length
        this.formData.target_teachers = "0," + e.teachers.join(',') + ",0"
      } else {
        this.formData.target_teachers = "0"
        this.choosedTeachers = 0
      }
      if (e.classes && e.classes.length > 0) {
        this.formData.target_classes = "0," + e.classes.join(',') + ",0"
        this.choosedClasses = e.classes.length
      } else {
        this.formData.target_classes = "0"
        this.choosedClasses = 0
      }
    },
    saveForm() {
      if (this.sendToAll) {
        this.formData.target_teachers = "all";
        this.formData.target_classes = "all";
      } else {
        //发送对象
        if (this.allStudent) {
          this.formData.target_classes = "all"
        }

        if (this.allTeacher) {
          this.formData.target_teachers = "all"
        }
      }
      let item = JSON.parse(JSON.stringify(this.formData));
      if (!item.files.length) {
        return this.$message.error('请上传文件')
      }
      let saveFile = idx => {
        if (idx < item.files.length) {
          let file = item.files[idx]
          let formData = {
            pid: this.pid,
            ftype: "file",
            filetype: file.filetype,
            filename: file.fileName,
            filesize: file.size,
            url: file.src,
            is_share: 1,
            target_classes: item.target_classes,
            target_teachers: item.target_teachers,
          }
          this.$http.post('/api/t_sch_filesstore_edit', formData).then(res => {
            saveFile(idx + 1)
          });
        } else {
          this.dialogshow = false
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        }
      }
      saveFile(0)
    },
    changeType(val) {
      this.deptType = val
    },
    handlePageChange(e) {
      this.Page.current_page = e
      this.getList()
    },
    handleSizeChange(e) {
      this.Page.per_page = e
      this.getList()
    },
    downloadFile() {
      if (!this.queryForm.class_ids) {
        this.deptType = 'xx'
      }
      let arr = this.year_month ? this.year_month.split('-') : []
      this.showProgress = true
      this.progressMsg = '准备文件中，请稍等...'
      this.progressPercent = 0
      this.progressStatus = 'success'
      this.$http.post('/api/monthReportDownload',{
        report_year: arr.length ? arr[0] : '',
        report_month: arr.length ? arr[1] : '',
        class_ids: this.queryForm.class_ids,
        dept_type: this.deptType,
      }).then(res => {
        //轮询获取进度
        this.timer = setInterval(() => {
          this.$http.get(`/api/progress_status?progress_mark=${res.data.progress_mark}`).then(res => {
            let data = res.data.data
            if (data.indexOf('done') > -1) {
              let fileUrl = data.substring(data.indexOf('-')+1)
              this.progressMsg = '压缩完成，正在下载'
              window.open(fileUrl,'_blank')
              clearInterval(this.timer)
            } else if (data.indexOf('compress') > -1) {
              this.progressPercent =  Number(data.substring(data.indexOf('-') + 1))
              this.progressMsg = '正在打包压缩文件，过程有点长，请稍等...'
            } else if (data == 'failed') {
              this.progressMsg = '下载失败，请重新下载'
              this.progressStatus = 'exception'
              clearInterval(this.timer)
            } else {
              this.progressPercent = Number(data)
            }
          }).catch(e => {
            console.log(e,'####')
            clearInterval(this.timer)
          })
        },2000)
      })


    },
    reloadFile() {
      let _this = this
      this.reloadApi().then(_ => {
        _this.$message.success('当月数据已重新生成~')
        setTimeout(() => {
          _this.Page.current_page = 1
          _this.getList()
        },500)
      })
    },
    reloadApi() {
      return new Promise((resolve, reject) => {
        let arr = this.year_month ? this.year_month.split('-') : []
        let params = {
          report_year: arr.length ? arr[0] : '',
          report_month: arr.length ? arr[1] : '',
          pmid: 229,
        }
        if (this.router_class_id) {
          params.class_id = this.router_class_id
        }
        console.log(12121221, this.router_class_id)
        // return
        this.$http.post('/api/generateMonthReportAll', params).then(res => {
          resolve()
        })
      })
    },
    onModify(row, type) {
      let arr = this.year_month ? this.year_month.split('-') : []
      this.params_dlg = {
        pmid: 229,
        class_id: row.class_id,
        report_year: arr.length ? arr[0] : '',
        report_month: arr.length ? arr[1] : '',
        class_name: row.class_name
      }
      // this.EditItem = row
      this.dialogshow1 = true
    },
    linkC() {
      this.$router.push("/sxgldxal_list")
    },
    linkD() {
      this.$router.push("/yxsxssj_list")
    },
    linkE() {
      this.$router.push("/zflist")
    },
    completeSxqkhz() {
      let _this = this
      this.DataList = []
      this.Page.current_page = 1
      this.reloadApi().then(_ => {
        setTimeout(() => {
          _this.getList()
        },500)
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }

};
</script>

<style scoped>
.filerow {
  padding: 10px;
  height: 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  line-height: 30px;
  display: flex;
  color: #333;
}

.filerow:hover {
  background-color: aliceblue;
}


.filename {
  width: 30%;
  padding-left: 10px;
}

.filedate {
  width: 120px;
  font-size: 12px;
}

.headimg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
}

.typeicon {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  vertical-align: middle;
}

.filenameitem {
  position: relative;
}

.fileicon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  color: #409EFF;
  display: block;
}

.filenameitem:hover .fileicon {
  display: block;
}

.filenameitem:hover {
  color: #409EFF;
  cursor: pointer;
}

.nextpath {
  font-size: 14px;
  color: #409EFF;
  cursor: pointer;
}
</style>