<template>
    <div class="bmList">
        <div class="" style="width:100%;padding:16px;">
            <div class="title" style="width:100%;color: #303133;font-size: 20px;font-weight:700;">
                南京市玄武职业教育66周年暨<br>
                南京市玄武中等专业学校成立20周年发展大会
            </div>

            <div class="title" style="margin-top:20px;width:100%;color: #909399;font-size: 14px;font-weight:700;">
                随着岁月的流转，玄武职业教育历经66年风雨砥砺，南京市玄武中等专业学校自成立以来也已有20载辉煌历程，现已发展成为一所拥有近5000名师生、三个校区、以五年制高等职业教育为主、综合高中和国际合作办学为辅的“一主两翼”办学格局的公办职业学校、国家级重点中等职业学校。为了庆祝这一具有特殊意义的时刻，彰显办学特色与文化底蕴，学校决定举办南京市玄武职业教育66周年暨南京市玄武中等专业学校成立20周年发展大会。
            </div>
        </div>
        <div class="" style="width:100%;padding:0 16px;">
            <div class="flexCenter" style="width:100%;padding:16px 0;border-top:1px solid #c0c4cc81;">
                <div class="flexCloumnCenter" style="width:50%;border-right:1px solid #c0c4cc81;">
                    <div style="color:#f56c6c;font-weight:700;">{{ stasticObj.money || '*' }}</div>
                    <div style="color:#909399;font-size:14px;margin-top:5px;">已筹(元)</div>
                </div>
                <div class=" flexCloumnCenter flex_1">
                    <div style="color:#f56c6c;font-weight:700;">{{ stasticObj.num||'*' }}</div>
                    <div style="color:#909399;font-size:14px;margin-top:5px;">已捐(人)</div>
                </div>
            </div>
        </div>
        <div class="" style="width:100%;height:10px;background-color:#f2f2f2f2;"> </div>
        <div class="flexStart" style="width:100%;border-bottom:2px solid #c0c4cc81;">
            <div @click="changeActive(0)" class="flexCloumnCenter" style="width:120px;">
                <div v-if="active == 0" class="" style="padding:10px 10px 6px 10px;font-weight:700;">项目详情</div>
                <div v-if="active == 0" style="width:20px;height:4px;background-color:#ff8400a1;border-radius:2px;">
                </div>
                <div v-if="active != 0" class=""
                    style="padding:10px 10px 6px 10px;font-weight:700;color:#909399;font-size:14px;">项目详情</div>
            </div>
            <div @click="changeActive(1)" class="flexCloumnCenter" style="width:120px;">
                <div v-if="active == 1" class="" style="padding:10px 10px 6px 10px;font-weight:700;">捐赠记录</div>
                <div v-if="active == 1" style="width:20px;height:4px;background-color:#ff8400a1;border-radius:2px;">
                </div>
                <div v-if="active != 1" class=""
                    style="padding:10px 10px 6px 10px;font-weight:700;color:#909399;font-size:14px;">捐赠记录</div>
            </div>
            <div v-if="pageFrom == 'mini' && phone " @click="changeActive(2)" class="flexCloumnCenter"
                style="width:120px;">
                <div v-if="active == 2" class="" style="padding:10px 10px 6px 10px;font-weight:700;">我的捐赠</div>
                <div v-if="active == 2" style="width:20px;height:4px;background-color:#ff8400a1;border-radius:2px;">
                </div>
                <div v-if="active != 2" class=""
                    style="padding:10px 10px 6px 10px;font-weight:700;color:#909399;font-size:14px;">我的捐赠</div>
            </div>
        </div>

        <div class="listBox" style="">
            <div v-if="active == 0" style="width:100%;margin-bottom:20px;padding:16px;">
                <div
                    style="width:100%;line-height:26px;overflow-wrap: break-word;white-space: pre-wrap;text-indent:2em; ">
                    亲爱的校友们，时光荏苒，玄武职业教育已走过 66
                    周年的辉煌历程。曾经，我们在这片充满希望的校园里共同成长、追逐梦想；如今，母校期盼着您的归来，携手共建更加美好的未来。欢迎来到校友捐赠平台，让我们一起为母校的明天贡献力量。
                    捐赠，不仅仅是一份物质上的支持，更是一种精神的传承。它承载着您对母校的深厚情谊，传递着玄武职业教育的优良传统。您的善举将激励着更多的学子奋发图强，努力拼搏，为实现自己的梦想而不懈奋斗。同时，也将为母校的发展增添新的动力，让我们共同书写玄武职业教育更加辉煌的篇章。<br>
                    资金用途<br>
                    项目捐赠用于支持玄武职业教育发展<br>
                    捐赠途径<br>
                    1.点击下方“我要捐赠”，填写相应金额捐款。<br>
                    2.银行汇款：<br>
                    银行户名：南京市玄武中等专业学校<br>
                    银行账号：487158191707<br>
                    开户行：中国银行南京花园路支行<br>
                </div>
                <div class="flexCenter" style="width:100%;">
                    <div @click="showQRcode" class="flexCenter button"
                        style="margin-top:20px;width:80%;height:40px;color:#fff;background-color:#1289ff;font-size:16px;font-weight:700;border-radius:25px;">
                        我要捐赠</div>
                </div>
            </div>
            <div v-if="active == 1" class="listItem" @click="goDetail(item)" v-for="(item, index) in list" :key="index">
                <div class="flexBetween" style="width:100%;">
                    <div class="" style="color: #303133;font-size: 16px;font-weight:700;">
                        {{ item.user_name }}
                    </div>
                    <div style="color:#909399;font-size:14px;">{{ item.donate_date }}</div>
                </div>
                <div class="flexBetween" style="width:100%;color: #606266;font-size: 14px;font-weight:700;">
                    <div></div>
                    <div>捐赠 <span style="color:#f56c6c;">{{ item.money||'*' }}</span>元</div>
                </div>
            </div>
            <div v-if="active == 2" class="listItem" @click="goDetail(item)" v-for="(item, index) in list" :key="index">
                <div class="flexBetween" style="width:100%;">
                    <div class="" style="color: #303133;font-size: 16px;font-weight:700;">
                        {{ item.user_name }}
                    </div>
                    <div style="color:#909399;font-size:14px;">{{ item.donate_date }}</div>
                </div>
                <div class="flexBetween" style="width:100%;color: #606266;font-size: 14px;font-weight:700;">
                    <div></div>
                    <div>捐赠 <span style="color:#f56c6c;">{{ item.money || '*' }}</span>元</div>
                </div>
            </div>
            <div v-if="active != 0 &&(!list || list.length == 0)" class="flexCloumnCenter"
                style="width: 100vw;justify-content:center;font-size: 16px;font-weight: 700; color: #aaaaaa;">
                <img src="../../../public/img/h5/defaulEmpty.png" alt="" style="width: 66%;">
                <span style="margin-bottom:20px;">未查询到相关记录，请稍后再看</span>

            </div>
            <div v-if="active != 0 && list && list.length > 0" class="flexCenter"
                style="font-size: 12px; color: #CCCCCC;margin-top:8px;">-- 到底了 --
            </div>
        </div>


        <div id="alert" class="alert flexCenter">
            <!-- <img @click="hideAlert" src="../../../public/img/h5/xwzzmini.png" alt="" style="width: 80vw; display: block;"> -->
            <img @click="hideAlert" src="../../../public/img/h5/zfcode.jpg" alt="" style="width: 80vw; display: block;">
        </div>
    </div>
</template>

<script>
// import * as defaultEmpty from "../../public/lottie/defaultEmpty.json"
import wx from 'weixin-js-sdk'

export default {
    data() {
        return {
            openid: '',
            list: [],
            searchValue: '',
            // defaultOptions: {
            //   animationData: defaultEmpty.default /*文件资源*/,
            // },
            color: ["1fa4fb", "3e6bff", "139efc", "149efc", "38aaf5", "2ea7f9", "3f6cfe", "109ffe", "4375ff"],
            showLoading: false,
            active: 0,
            showimg: false,
            stasticObj: {},
            pageFrom: '',
            phone:''
        }
    },
    mounted() {
        document.title = "校友捐赠详情"
        this.openid = this.$route.query.openid
        this.getStastic()
        if (this.openid) {
            // this.getList('')
        } else {
            this.$message.warning('登录信息失效，请用微信扫码进入该页面')
        }
        if (this.$route.query.page) {
            this.pageFrom = this.$route.query.page
        }
        if (this.$route.query.phone) {
            this.phone = this.$route.query.phone
        } 
        // this.checkUrlSign(wx)

    },
    updated() {
        document.scrollingElement.scrollTop = 0
    },

    methods: {
        changeActive(e) {
            this.active = e
            if (e==1) {
                this.getAllList()
            } else if (e == 2) {
                this.getMyList()
            }
        },
        getAllList() {
            this.showLoading = true
            this.list = []
            this.$http.post(`/api/by_donate_list`, {
                openid: this.openid,
                page: 1,
                pagesize: 10000,
                pmid: 209,
                status: 1,
                isMoney: 1,
                sortbymoney:1,
            }).then(res => {
                if (res.data && res.data.data && res.data.data.length > 0) {
                    // res.data.data.map(a => {
                        // a.udate = a.udate.substring(0, 10)
                        // a.teacherNames = a.teachers.map((n) => {
                        //     return n.name
                        // }).join('，')
                    // })
                    this.list = res.data.data
                } else {
                    this.list = []
                }
                this.showLoading = false

            })
        },
        getMyList() {
            this.showLoading = true
            this.list = []
            this.$http.post(`/api/this_donate_list`, {
                openid: this.openid,
                page: 1,
                pagesize: 10000,
                pmid: 209,
                status: 1,
                isMoney: 1,
                phone: this.phone
            }).then(res => {
                if (res.data && res.data.data && res.data.data.length > 0) {
                    // res.data.data.map(a => {
                    // a.udate = a.udate.substring(0, 10)
                    // a.teacherNames = a.teachers.map((n) => {
                    //     return n.name
                    // }).join('，')
                    // })
                    this.list = res.data.data
                } else {
                    this.list = []
                }
                this.showLoading = false

            }) 
        },
        getStastic() {
            this.$http.post(`/api/xwzz_by_donate_stastic`, {
                pmid: 209,
            }).then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.stasticObj = res.data[0]
                } else {
                    this.stasticObj = {}
                }
                this.showLoading = false

            })
        },
        showQRcode() {
            // window.open('https://ymv.chinaums.com/h5/index.html?qrCode=91EC278409AE4C628E5C2B26E38233CD')

            this.showimg = true
            this.showAlert()
        },
        showAlert(msg) {
            $("#alert").css("display", "flex").animate({
                opacity: 1
            }, 200, "linear", function () {

            })
        },
        showAlert1() {
            wx.miniProgram.getEnv((res) => {
                console.info("res", res);
                if (res.miniprogram) {
                    wx.checkJsApi({
                        jsApiList: ['scanQRCode', 'previewImage'],
                        success: function (res) {
                            alert(7)
                        }
                    });
                    wx.previewImage({
                        current: 'https://common-oss-files.oss-cn-hangzhou.aliyuncs.com/qsxsaas/files/2024-12-23/1734948559051539.jpg', // 当前显示图片的http链接
                        urls: ['https://common-oss-files.oss-cn-hangzhou.aliyuncs.com/qsxsaas/files/2024-12-23/1734948559051539.jpg'] // 需要预览的图片http链接列表
                    });
                    alert(2222);
                }
            });
        },
        hideAlert() {
            $("#alert").animate({
                opacity: 0
            }, "linear", 3000, function () {
                $("#alert").hide()
            })
        },
        getList(val) {
            this.showLoading = true
            this.list = []
            this.$http.post(`/api/project/list/${this.openid}`, {
                search: val,
                openid: this.openid,
                page: 1,
                pagesize: 10000,
            }).then(res => {
                if (res.data.data && res.data.data.items && res.data.data.items.length > 0) {
                    res.data.data.items.map(a => {
                        a.udate = a.udate.substring(0, 10)
                        a.teacherNames = a.teachers.map((n) => {
                            return n.name
                        }).join('，')
                    })
                    this.list = res.data.data.items
                }
                this.showLoading = false

            })
        },

        goDetail(row) {
            this.$router.push({
                path: '/xwzz_donate_detail',
                query: {
                    openid: this.openid,
                    id: row.id || 0
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
.bmList {
    /*position: fixed;
    z-index: 10;
    top: 0;
    left: 0;*/
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-size: cover;
    overflow: auto;
    background-color: #fff;

    .listBox {
        width: 100%;
        box-sizing: border-box;

        div {
            box-sizing: border-box;
        }

        .listItem {
            width: 100%;
            border-bottom: 1px solid #c0c4cc81;
            /*border-left: 3px solid #409eff;
            border-radius: 8px;*/
            //font-size: 14px;
            padding: 16px;
            position: relative;
            /*box-shadow: 1px -2px 1px hsla(220deg, 60%, 50%, 0.05),
                1px 1px 1px hsla(220deg, 60%, 50%, 0.05);*/
            //2px 4px 4px hsla(220deg, 60%, 50%, 0.2),
            //4px 8px 8px hsla(220deg, 60%, 50%, 0.2);
            margin-bottom: 0px;
        }
    }
}

.button {
    box-shadow:5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    text-shadow: 2px 2px 4px #000000;
}
.alert {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 99;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
    }
</style>
