<template>
    <div>
        <div style="padding-left:40px" v-if="bzrList.length > 0">
            <el-date-picker v-if="signLevel == 'week'" @change="changeDate" size="small" v-model="searchForm.year_week"
                style="width: 160px;margin-left:10px" type="week" format="yyyy 第 WW 周" placeholder="选择周">
            </el-date-picker>

            <el-date-picker v-if="signLevel != 'week'" @change="getMyClasses" value-format="yyyy-MM-dd"
                v-model="searchForm.date" style="width: 150px;margin-left:10px" type="date" placeholder="选择日期"
                size="small"></el-date-picker>

        </div>
        <div v-for="(item, idx) in bzrList" :key="idx" style="display:flex; margin-top:20px; ">

            <div :id="'bzrchart' + item.class_id" style="width:400px; height:400px"></div>

            <div style="flex-grow: 1;max-height:500px;overflow: auto; padding:0 30px">
                <!-- <table class="dttable" height="500px">
                    <tr>
                        <td class="tdheader" style="width:30px">序号</td>
                        <td class="tdheader" style="width:60px">姓名</td>
                        <td class="tdheader" style="width:80px">学号</td>
                        <td class="tdheader" style="width:80px">班级</td>
                        <td class="tdheader" style="width:130px">系部</td>
                        <td class="tdheader" style="width:100px">是否打卡</td>
                        <td class="tdheader">企业名称</td>
                    </tr>
                    <tr v-for="(stu, i) in item.list" :key="stu.id">
                        <td>{{ i + 1 }}</td>
                        <td>{{ stu.stu_name }}</td>
                        <td>{{ stu.stu_code }}</td>
                        <td>{{ stu.class_name }}</td>
                        <td>{{ stu.yx_name }}{{ stu.major_name }}</td>
                        <td>{{ stu.sign_date ? stu.sign_date : '未打卡' }}</td>
                        <td>{{ stu.firm_name }}</td>
                    </tr>
                </table> -->

                 <el-table v-tableFit :data="item.list" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
                    <el-table-column type="index" width="65" label="序号" align="center" />
                    <el-table-column prop="stu_code" label="学号" show-overflow-tooltip width="135" align="center" />
                    <el-table-column prop="stu_name" label="学生姓名" width="100" align="center" />
                    <!-- <el-table-column prop="class_name" label="班级" show-overflow-tooltip width="100" align="center" />
                    <el-table-column prop="title" label="系部" show-overflow-tooltip min-width="180" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.yx_name }} {{ scope.row.major_name }}
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="sign_address" label="打卡位置" show-overflow-tooltip width="160" align="center">
                        <template slot-scope="scope">{{ scope.row.sign_address || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="sign_date" label="打卡时间" width="200" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sign_time">{{ scope.row.sign_time }}</span>
                            <span class="pointer" v-else-if="scope.row.leaveid" @click="showLeaveDetail(scope.row)"
                                style="color:orangered;">请假</span>
                            <span v-else style="color:gray">未打卡</span>
                        </template>
                    </el-table-column>


                    <el-table-column prop="firm_name" label="实习企业名称" show-overflow-tooltip min-width="180" align="center" />

                </el-table>
            </div>

        </div>

        <div style="height:200px"></div>


        <!-- 请假详情 -->
        <el-dialog title="请假详情" :visible.sync="showLeaveDialog" :close-on-click-modal="false" custom-class="cus_dialog"
            width="80%">
             <el-table v-tableFit :data="leaveList" row-key="id" border stripe style="margin-top:10px;">
                <el-table-column prop="stu_name" label="学生姓名" width="100" align="center" />
                <el-table-column prop="class_name" label="班级" show-overflow-tooltip width="130" align="center" />
                <el-table-column prop="title" label="标题" show-overflow-tooltip width="180" align="center" />


                <el-table-column prop="leave_type" label="类型" show-overflow-tooltip width="100" align="center" />
                <el-table-column prop="leave_num" label="请假天数" show-overflow-tooltip width="100" align="center" />


                <el-table-column prop="cdate" label="请假时间" show-overflow-tooltip width="240">
                    <template slot-scope="scope">
                        <span style="color: blue;">{{ scope.row.leave_start }}</span>

                        <span style="color: blue;"> ~</span><br />
                        <span style="color: blue;">{{ scope.row.leave_end }}</span>

                    </template>

                </el-table-column>
                <el-table-column prop="leave_content" label="请假原因" show-overflow-tooltip width="160"
                    align="center"></el-table-column>

                <el-table-column prop="cdate" label="提交时间" show-overflow-tooltip width="200" align="center" />

                <el-table-column prop="sp_username" label="审批人" width="90" align="center" />


            </el-table>
        </el-dialog>
    </div>
</template>
  
<script>
import util from "../../../utils/util.js"
export default {
    data() {
        return {
            searchForm: {
                class_ids: "",
                ktype: "stu_name",
                keyword: "",
                dk: "",
                date: util.formatDate(),
                year_week: new Date(),
                weekStart: "",
                weekEnd: "",


            },
            bzrList: [],
            signLevel: "",
            showLeaveDialog: false,
            leaveList: []
        }
    },
    mounted() {
        this.getMyClasses()


    },
    methods: {
        changeDate(e) {

            let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")
            this.searchForm.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
            this.searchForm.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")
            this.getMyClasses()
        },
        getMyClasses() {

            this.$http.post("/api/get_teacher_info").then(res => {
                let bzr = []
                if (res.data.class_names && res.data.class_names.length > 0) {
                    for (let item of res.data.class_names) {
                        if (item.type == "班主任") {
                            item.list = []
                            bzr.push(item)
                            this.getList(item)
                        }
                    }
                }
                this.bzrList = bzr
                // console.log(116, this.bzrList)

            })
        },
        getList(item) {
            let data = {};
            data.class_ids = item.class_id;
            data.sign_date = this.searchForm.date;
            data.sign_start = this.searchForm.weekStart;
            data.sign_end = this.searchForm.weekEnd;
            data.pagesize = 100000;

            this.$http.post("/api/sign_records_list", data).then(res => {
                this.signLevel = res.data.dk_lvl
                item.list = res.data.data
                this.initPie(item, res.data.data)
            });
        },

        initPie(item, data) {

            let option = {
                title: {
                    text: item.class_name + '打卡详情【' + (this.signLevel == 'week' ? this.searchForm.year_week : this.searchForm.date) + '】',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: "打卡情况",
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        data: [

                        ],
                        color: ['#2469f3', '#cccc99', '#ff6666', '#cccc00', '#996699', '#333333'],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };




            //就业率
            let daka_count = 0;
            let fenmu = 0;
            for (let item of data) {
                if (!item.leaveid && item.sign_date) {
                    daka_count++;
                }
                if (!item.leaveid) {
                    fenmu++
                }

            }
            let xdata = [{ value: daka_count, name: '已打卡' }, { value: fenmu - daka_count, name: '未打卡' }];
            option.series[0].name = "打卡人数"
            option.series[0].data = xdata;
            this.$echarts.init(document.getElementById('bzrchart' + item.class_id)).setOption(option);



        },
        showLeaveDetail(row) {
            this.$http.post("/api/stu_leave_one", {
                id: row.leaveid
            }).then(res => {
                this.leaveList = [res.data];
                // console.log(this.leaveList)
                this.showLeaveDialog = true;
            });
        },

    },

};
</script>
  
