<template>
  <div v-if="EditItem">
    <el-dialog title="实习生事迹详情" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
      :destroy-on-close="true" @close="closeDialog" width="800px" custom-class="cus_dialog">
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="学生姓名">
                <el-select v-model="EditItem.stu_name" :disabled="dialogxx" filterable remote reserve-keyword
                  placeholder="请输入姓名" :remote-method="remoteMethod" :loading="loading" style="width:180px"
                  @change="checkedxz" @focus="focusxz">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="学号">
                <el-input v-model="EditItem.stu_code" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="班主任">
                <el-select v-model="EditItem.head_teacher" filterable placeholder="请选择班主任" style="width:180px"
                  :disabled="dialogxx">
                  <el-option v-for="(item, idx) in bzrOptions" :key="idx" :label="item.username"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="班级名称">
                <el-input v-model="EditItem.class_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="指导老师">
                <el-select v-model="EditItem.instructor" filterable placeholder="请选择指导老师" style="width:180px"
                  :disabled="dialogxx">
                  <el-option v-for="(item, idx) in zdlsOptions" :key="idx" :label="item.username" :value="item.id"
                    filterable:true></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="实习单位">
                <el-select v-model="EditItem.enter_name" filterable placeholder="请选择实习单位" style="width:180px">
                  <el-option v-for="(item, idx) in enterList" :key="idx" :label="item.firm_name" :value="item.firm_name"
                    filterable:true></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="22">
              <el-form-item label="标题">
                <el-input v-model="EditItem.title" :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="$store.state.pmid == 229" style="padding-top: 10px;">
            <el-col :span="22">
              <el-form-item label="内容">
                <el-input v-model="EditItem.event_desc" type="textarea" :autosize="{ minRows: 10 }"
                  :disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item v-if="$store.state.pmid != 229" label="内容">
            <el-col :span="22">
              <div style="line-height: initial; width: 100%; ">
                <template v-if="$store.state.pmid == 214">
                  <p style="color: red;">* 注: 内容不小于三个层次(综合概述+ 做法 + 成效 )字数必须大于200字</p>
                  <p style="color: red;">第一层：简单概括学校在该方面的高度重视、特色创新或是现状</p>
                  <p style="color: red;">第二层：简述学校在该方面的主要做法、实施路径、推进过程等</p>
                  <p style="color: red;">第三层：近年来取得的成效与成绩</p>
                </template>
                <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="上传图片">
            <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
              v-for="(img, idx) in EditItem.annex" :key="idx">
              <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
                :preview-src-list="[img.fileUrl || img.src]" fit="cover"></el-image>

              <div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)">
                <i class="el-icon-remove"></i>
              </div>
            </div>

            <div style="display:inline-block;vertical-align: top;" v-if="EditItem.is_draft == 1">
              <el-upload style="display: inline-block; margin-right: 20px" action
                :http-request="uploadOss1" :on-success="uploadFiles" :show-file-list="false" name="image">
                <div class="imgaddbtn">＋</div>
              </el-upload>
            </div>
          </el-form-item>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="填报时间">
                <el-date-picker v-model="EditItem.report_time" :disabled="disabled" style="width:180px" align="right"
                  type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="排序号">
                <el-input-number v-model="EditItem.sort" :disabled="disabled" controls-position="right"
                  style="width:180px"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col :span="22" style="text-align: right;padding-left: 10px;"
            v-if="$store.state.teacher.id == EditItem.create_id || EditItem.id == 0">
            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UEditor from "../notify/ueditor.vue";
import util from "../../utils/util.js";
// 压缩
import Compressor from 'compressorjs';

export default {
  components: { UEditor },
  props: ["id"],
  data() {
    return {
      thisTeacherid: 0,
      dialogshow: true,
      dialogxx: true,
      disabled: true,
      editor: null,
      optionsRow: {
        stu_code: "",
        stu_id: "",
        stu_name: "",
        class_name: ""
      },
      EditItem: {
        is_draft: 0,
        title1: "",
        id: 0,
        stu_id: 0,
        stu_code: "",
        stu_name: "",
        title: "",
        class_name: "",
        enter_name: "",
        instructor: "",
        instructor_name: "",
        head_teacher: "",
        head_teacher_name: "",
        create_id: 0,
        event_desc: "",
        annex: [],
        report_time: "",
        create_name: "",
        sort: 99
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      enterList: [],
      options: [],
      bzrOptionsls: [],
      bzrOptions: [],
      zdlsOptionsls: [],
      zdlsOptions: [],
      list: [],
      loading: false,
      states: [],
      fileList: []
    };
  },
  mounted() {
    this.enter_list();
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        res.report_time = util.formatDate(new Date());
        this.disabled = false;
        this.dialogxx = false;
      } else {
        res.annex = JSON.parse(res.annex);
        this.options = [{ id: res.stu_id, label: res.stu_code }];
        this.zdlsOptions = [
          { id: res.instructor, username: res.instructor_name }
        ];
        this.bzrOptions = [
          { id: res.head_teacher, username: res.head_teacher_name }
        ];
        if (this.$store.state.teacher.id == res.create_id) {
          this.disabled = false;
          res.is_draft = 1;
        } else {
          //修改不是自己的
        }
        setTimeout(() => {
          if (this.$store.state.pmid != 229) {
            this.editor.setContent(this.EditItem.event_desc);
          }
        }, 500);
      }
      this.EditItem = res;
    });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.$http
          .post("/api/students_class_info", { stu_code: query })
          .then(res => {
            this.loading = false;
            if (res.data) {
              this.options = [
                {
                  value: res.data.id,
                  label: res.data.stu_name + "-" + res.data.stu_code
                }
              ];
              this.bzrOptionsls = res.data.bzr_ist;
              this.zdlsOptionsls = res.data.zdls_list;
              this.optionsRow.stu_code = res.data.stu_code;
              this.optionsRow.stu_id = res.data.id;
              this.optionsRow.stu_name = res.data.stu_name;
              this.optionsRow.class_name = res.data.class_name;
              this.optionsRow.enter_name = this.enterList.filter(e => e.id == res.data.enter_id).map(m => m.firm_name).pop()
            }
          });
      } else {
        this.options = [];
      }
    },
    checkedxz() {
      this.dialogxx = false;
      this.bzrOptions = this.bzrOptionsls;
      this.zdlsOptions = this.zdlsOptionsls;

      this.EditItem.head_teacher = this.bzrOptions[0].id;
      if (this.zdlsOptions.length > 0) {
        this.EditItem.instructor = this.zdlsOptions[0].id;
      }

      this.EditItem.stu_code = this.optionsRow.stu_code;
      this.EditItem.stu_id = this.optionsRow.stu_id;
      this.EditItem.stu_name = this.optionsRow.stu_name;
      this.EditItem.class_name = this.optionsRow.class_name;
      this.EditItem.enter_name = this.optionsRow.enter_name || ""
    },
    focusxz() {
      this.options = [];
      this.bzrOptionsls = [];
      this.zdlsOptions = [];
      this.optionsRow.instructor = "";
      this.optionsRow.head_teacher = "";
      this.optionsRow.stu_code = "";
      this.optionsRow.stu_id = "";
      this.optionsRow.stu_name = "";
      this.optionsRow.class_name = "";
    },
    uploadFiles(e) {
      console.log(300, e)
      if (e && (e.src||e.fileUrl)) {
        this.EditItem.annex.push(e);

      }
    },
    removeImg(idx) {
      this.EditItem.annex.splice(idx, 1);
    },
    enter_list() {
      this.$http.post("/api/enterprise_list_all").then(res => {
        this.enterList = res.data;
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6, // 压缩质量，取值0-1
          success: (result) => {

            resolve(result); // 压缩成功，返回压缩后的文件
          },
          error(err) {
            console.log(err.message);
            reject(err.message); // 压缩失败，返回错误信息
          },
        });
      });
    },

  
    uploadOss1(fileparams) {
      console.log(336,fileparams)

      let file = fileparams.file
      const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      const isLt500K = file.size / 1024 < 5120;//图片上限5M
      console.log(349, isJPGOrPNG, isLt500K)

      if (!isJPGOrPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
        return false;
      }

      if (isLt500K) {
        // 文件大小符合要求，直接上传
        this.uploadOss(fileparams)
        // return true;
      } else {
        console.log(349, isJPGOrPNG, isLt500K)

        // 文件大小超过限制，进行压缩
        return this.compressImage(file).then(compressedFile => {
          // 这里可以将compressedFile替换掉原始file对象，但element-ui可能不支持直接替换
          // 另一种方式是上传压缩后的文件，这里简单处理为返回true，实际使用时需上传compressedFile
          // 或者你可以在这里调用自定义的上传函数，传入compressedFile
          var myDate = new Date();
          var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
          // 获取文件后缀
          var pathArr = compressedFile.name.split('.')
          //  随机生成文件名称
          var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
          var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
          // 要提交的key
          var fileKey = ossPath + "/" + fileName;
          let formData = {
            name: compressedFile.name,
            key: fileKey,
            policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
            OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
            signature: '7a7dfIH8bpoHXTeZxd+3xGS/g54=',
            success_action_status: "200",
            app: "qsxsaas"

          };
          var host = 'https://oss.aiwx.org.cn/upload';
          var outurl = host
          var form = new FormData();
          // 其他参数
          for (let k in formData) {
            form.append(k, formData[k]);
          }
          form.append("file", compressedFile);
          // XMLHttpRequest 对象
          var xhr = new XMLHttpRequest();
          xhr.open("post", host, true);
          xhr.upload.addEventListener("progress", fileparams.onProgress, false); //监听上传进度
          xhr.onload = (res) => {
            if (fileparams.onSuccess) {
              let resObj = res.currentTarget.responseText
              try {
                resObj = JSON.parse(resObj)
              } catch (e) {
                resObj = {}
              }
              resObj.size = compressedFile.size;
              fileName = fileName.toLocaleLowerCase();
              //计算图片高度
              if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
                fileName
                  .indexOf(
                    ".gif") > 0 || fileName.indexOf(".bmp") > 0) {

                var reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = function (theFile) {
                  var image = new Image();
                  image.src = theFile.target.result;
                  image.onload = function () {
                    if (resObj.src) {
                      resObj.src += "?s=" + this.width + "_" + this.height
                    }
                    fileparams.onSuccess(resObj)

                  };
                };

              } else {
                fileparams.onSuccess(resObj)
              }

            }
          };
          xhr.onerror = res => {
            if (fileparams.onError) {
              fileparams.onError(res)
            }

          }
          xhr.send(form);

          return true; // 或者你可以在这里调用自定义的上传函数，传入compressedFile
        }).catch(err => {
          this.$message.error(err);
          return false;
        });
      }
      
    },


    submitForm(st) {
      let EditItem = { ...this.EditItem };
      EditItem.annex = JSON.stringify(EditItem.annex);
      let data = JSON.parse(JSON.stringify(EditItem));
      let words = window.pmid == 214 ? 200 : 50
      if (data.event_desc.length < words) {
        this.$message.error("内容必须大于" + words + "字!");
        return false;
      }
      if (!data.title.trim()) {
        this.$message.error("标题不能为空!");
        return false;
      }
      if (!data.stu_id) {
        this.$message.error("学生必选!");
        return false;
      }
      if (!data.enter_name.trim()) {
        this.$message.error("实习单位必填!");
        return false;
      }
      if (!data.report_time.trim()) {
        this.$message.error("填报时间必选!");
        return false;
      }
      if (!data.head_teacher) {
        this.$message.error("班主任必选!");
        return false;
      }
      // if (!data.instructor) {
      //   this.$message.error("指导老师必选!");
      //   return false;
      // }
      this.$http.post("/api/stu_event_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.event_desc);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.event_desc = editorInstance.getContent();
      });
      this.editor = editorInstance;
    }
  }
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
