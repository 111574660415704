<template>
	<div class="pageContainer targetPage">
		<el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
			<div class="flexStart flex_1" style="flex-wrap:wrap;">

				<span style="margin: 0 0 10px 0;">选择班级：</span>

				<tchtree v-model="deptId" @change="getStudents" style="margin: 0 10px 10px 0;"></tchtree>


				<span v-if="$store.state.pmid == 215" style="margin: 0 10px 10px 10px;">届次：</span>
				<el-select v-if="$store.state.pmid == 215" clearable v-model="searchForm.school_year" size="small"
					placeholder="请选择" style="max-width:200px;margin: 0 10px 10px 0;"
					@change="() => { $forceUpdate(); getStudents() }">
					<el-option v-for="item in schoolYears" :key="item.value" :label="item.title" :value="item.value">
					</el-option>
				</el-select>

				<span style="margin: 0 10px 10px 0;">请输入关键字：</span>
				<el-input v-model="searchForm.keyword" size="small" placeholder="请输入关键字，多关键字使用空格隔开" clearable
					style="width: 200px;margin: 0 10px 10px 0;" />
				<span style="margin: 0 10px 10px 0;">状态：</span>
				<el-select v-model="searchForm.biye" clearable size="small" style="width:100px;margin: 0 10px 10px 0;">

					<el-option :value="1" label="已完善"></el-option>
					<el-option :value="0" label="未完善"></el-option>
				</el-select>

				<el-button size="small" icon="el-icon-search" @click="() => { page.current_page = 1; getStudents() }"
					type="primary" style="margin: 0 10px 10px 0;">查询</el-button>

				<el-button type="primary" plain size="small" icon="el-icon-upload2" @click="importTeacherShow = true"
					style="margin: -10px 10px 0 0;">导入学生</el-button>


				<el-button type="primary" plain size="small" icon="el-icon-upload2" @click="importJobShow = true"
					style="margin: -10px 10px 0 0;">导入就业信息</el-button>


				

				<el-button type="primary" plain size="small" icon="el-icon-upload2" @click="exportZip"
					style="margin: -10px 10px 0 0;">导入就业资料</el-button>


					<el-button type="primary" plain size="small" icon="el-icon-download" @click="exportXls"
					style="margin: -10px 10px 0 0;">导出数据</el-button>
        <el-button type="primary" size="small" plain icon="el-icon-refresh" @click="j1jobUpdate" style="margin: -10px 10px 0 0;">同步91job</el-button>
<!--        <el-button type="primary" plain size="small" icon="el-icon-download" @click="downloadAttach"
                   style="margin: -10px 10px 0 0;">下载附件</el-button>-->

			</div>
			<div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">


			</div>
		</el-row>
		<div class="dividerBar"></div>

		<div style="width：100%">

			<el-table :data="tableData" width="100%" stripe border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center">
				</el-table-column>
				<el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>


				<el-table-column prop="stu_name" label="学生姓名" width="100" align="center">
					<template slot-scope="scope">
						<span style="color: #007AFF; cursor: pointer;" v-if="scope.row.openid"
							@click="showStuBind(scope.row)">{{ scope.row.stu_name }}</span>
						<span v-else>{{ scope.row.stu_name }}</span>
						<i class="el-icon-link" v-if="scope.row.openid"></i>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="性别" width="60" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.gender == 1">男</span>
						<span v-if="scope.row.gender == 2">女</span>
						<span v-if="!scope.row.gender">-</span>
					</template>
				</el-table-column>

				<el-table-column prop="phone" label="手机号" min-width="135" align="center"></el-table-column>
				<el-table-column prop="class_name" label="班级" width="130" align="center"></el-table-column>
				<el-table-column prop="zhuanye_alias" label="专业" min-width="130" align="center">

					<template slot-scope="scope">
						{{ scope.row.zhuanye_alias }}
					</template>
				</el-table-column>

				<el-table-column prop="minzu" label="民族" width="130" align="center"></el-table-column>
				<el-table-column prop="zzmm" label="政治面貌" width="130" align="center"></el-table-column>
				<el-table-column prop="xueli" label="学历" width="130" align="center"></el-table-column>
				<el-table-column prop="skill_lvl" label="技能等级" width="130" align="center"></el-table-column>
				<el-table-column prop="class_name" label="是否完善" min-width="130" align="center">

					<template slot-scope="scope">
						<el-tag v-if="!scope.row.job_joined" type="danger" size="mini">否</el-tag>
						<el-tag v-else type="success" size="mini">是</el-tag>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="260" align="center">
					<template slot-scope="scope">
						<div>
							<el-popconfirm v-if="!scope.row.locked" width="250" confirmButtonText='确定' cancelButtonText='取消'
								icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)" placement="left"
								title="是否确定删除？">
								<el-tag class="pointer" slot="reference" type="danger" size="mini"
									style="margin-right: 10px;">删除</el-tag>

							</el-popconfirm>
							<el-tag v-if="!scope.row.locked" class="pointer" @click="handleModify(scope.row)" type="primary"
								size="mini">修改</el-tag>



						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
				:current-page="page.current_page" :page-size="page.per_page" :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
				layout="total, prev, pager, next,sizes, jumper" :total="page.count">
			</el-pagination>

		</div>



		<el-dialog title="导入学生基本信息" :visible.sync="importTeacherShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true" custom-class="cus_dialog">
			<div style="text-align:center">
				<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
					:on-success="handleXlsUpload" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
				</el-upload>
				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a :href="'/xls/南京技师学院学生导入模板.xlsx'" download="学生导入模板"
						style="text-decoration: none;color:inherit">下载学生信息导入模板</a>
				</el-button>
			</div>
		</el-dialog>

		<el-dialog title="导入就业信息" :visible.sync="importJobShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true" custom-class="cus_dialog">
			<div style="text-align:center">
				<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
					:on-success="handleXlsUpload2" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择文件</el-button>
				</el-upload>
				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a :href="'/xls/南京技师学学院-学生就业信息模板.xlsx'" download="学生就业信息模板" style="text-decoration: none;color:inherit">下载学生信息导入模板</a>
				</el-button>

			</div>

			<div style="text-align:center;color:orangered;padding-top: 50px;">提示：就业信息只能导入学生未上报的数据，已经上报需要修改，请点击右侧修改按钮直接修改即可！
			</div>
		</el-dialog>

		<el-dialog title="导入就业资料" :visible.sync="importZipShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true" custom-class="cus_dialog">
			<div style="text-align:left">

				<div style="display: flex; padding-bottom: 20px;">
					<div style="width:80%;color: orangered;">
						请将文件放至一个zip格式压缩包里，文件命名格式【身份证号-资料名称.jpg】<br />"资料名称"可随意命名。Zip文件不要超过1G
					</div>
					<div>
						<el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
							:on-success="handleZipUpload" :show-file-list="false" name="image">
							<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">选择Zip文件</el-button>
						</el-upload>
					</div>
				</div>

				<div style="display: flex; padding-bottom: 20px;">没有压缩软件？ <a href="https://dl.360safe.com/360zip_setup.exe" target="_blank">点这里下载！</a> </div>


				<div>
					<img :src="'./img/zip.png'" style="width: 100%;" />
				</div>
				<!-- <el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a :href="'/xls/南京技师学院学生导入模板.xlsx'" style="text-decoration: none;color:inherit">下载学生信息导入模板</a>
				</el-button> -->
			</div>
		</el-dialog>

		<stuform v-if="editDialogShow" :stuinfo="formData"></stuform>


    <!---下载进度--->
    <el-dialog title="下载进度" :visible.sync="showProgress" width="800px" :close-on-click-modal="false">
      <el-progress :text-inside="true" :stroke-width="20" :percentage="progressPercent" :status="progressStatus"></el-progress>
      <span>{{progressMsg}}</span>
    </el-dialog>
	</div>
</template>
<script>

import tchtree from "../com/tchTree.vue";
import stuform from "./stuform.vue";
export default {
	name: "dept",
	components: {
		tchtree, stuform
	},
	data() {
		return {
			visible: false,
			isShowModal: false,
			isShowModalAuth: false,
			importTeacherShow: false,
			importJobShow: false,
			editDialogShow: false,
			importZipShow: false,
			modalTitle: "添加",
			DeptUserDialog: false,
			activeRoleID: "",
			biye: 0,
			formData: {},
			formTeacher: {
				bzr: "",
				zdls: []
			},
			ckdTreeData: [],

			defaultProps: {
				label: "name",
				value: "id",
				children: "children",
				emitPath: false,
				checkStrictly: true
			},

			activeName: "CN",
			tableData: [],

			teacherList: [],
			editUser: {},
			deptId: 0,
			deptName: "",
			editDeptRole: {},
			deptRoleList: [],
			isShowDeptRole: false,

			page: {
				count: 0,
				current_page: 1,
				per_page: 50,
				total_page: 0
			},
			searchForm: {
				biye: "",
				ktype: "stu_name",
				keyword: ""
			},
			multipleSelection: [],
			treeData: [],
			firstCascaderProps: {
				label: "name",
				children: "children"
			},
			DataAuth: [],
			showTeacherInfo: false,
			student_id: 0,
			needSp: false,
			qxArr: [],
			dictArr: [],
			docxData: {},
			dialogExportFormVisible: false,
			export_format: 'word',
			schoolYears: [],
      showProgress: false,
      progressPercent: 0,
      progressMsg: '',
      progressStatus: 'success',
      timer: null,
      chunkSize: 1024*1024*20,
      totalChunk: 0,

		};
	},

	mounted() {
		this.getStudents()
	},
	methods: {
		handlePageChange(page) {
			this.page.current_page = page;
			this.getStudents();
		},
		handleSizeChange(val) {
			this.page.current_page = 1;
			this.page.per_page = val;
			this.getStudents(1)
		},

		handleXlsUpload(e) {
			this.$http.post("/api/import_student_xls_njjs", { url: e.src }).then(res => {
				let error = res.data.error
				if (error.length) {
					let _html = "<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
					for (let value of error) {
						_html += `<p style="line-height: 30px;">${value}</p>`
					}
					_html += '</div>'
					this.$alert(_html, '导入失败学生', {
						dangerouslyUseHTMLString: true,
					})
				} else {
					this.$message.success("导入成功");
				}
				this.getStudents();
				this.importTeacherShow = false;
			});
		},
		handleXlsUpload2(e) {
			this.$http.post("/api/import_student_job_njjs", { url: e.src }).then(res => {
				let error = res.data.error
				if (error.length) {
					let _html = "<div style='max-height: 600px;line-height:28px;text-align:center;overflow-y: auto'>"
					for (let value of error) {
						_html += `<p style="line-height: 30px;">${value}</p>`
					}
					_html += '</div>'
					this.$alert(_html, '导入失败学生', {
						dangerouslyUseHTMLString: true,
					})
				} else {
					this.$message.success("导入成功");
				}
				this.getStudents();
				this.importJobShow = false;
			});
		},
		handleZipUpload(e){
			
			this.$http.post("/api/njjs_upload_zip", { url: e.src }).then(res => {
				this.$message.success("上传成功，请等待解析！");
				this.importZipShow = false
			});
		},

		getTree1() {
			this.$http.post("/api/get_tch_cls_data", {}).then(res => {
				// res.data.list.map(e => {
				// 	e.name = e.upname + "/" + e.name
				// })
				this.treeData = res.data.list;
				this.ckdTreeData = this.treeData;
			});
		},


		getDic() {
			return new Promise(resolve => {
				this.$http
					.post("/api/sys_param_list", { param_type: "'SHIXI_STATUS'" })
					.then(res => {
						let qxArr = [];
						res.data.map((item, index, arr) => {
							if (item.param_type == "SHIXI_STATUS") {
								qxArr.push(item);
							}
						});
						this.qxArr = qxArr;
						resolve();
					});
			});
		},

		getStudents() {
			let params = {
				page: this.page.current_page,
				pagesize: this.page.per_page,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				// dept_id: this.searchForm.keyword ? '' : this.deptId,
				// class_id: this.searchForm.keyword ? '' : this.deptId,
				office_id: this.deptId,
				joined: this.searchForm.biye,
				school_year: this.searchForm.school_year,
			};

			// sys_dept_user_related_list
			this.$http.post("/api/t_sch_students_list_njjs", params).then(res => {


				this.tableData = res.data.data;
				this.page = res.data.page;
			});
		},
		addStudents() {
			// let ckdClass = this.$refs['firstCascader'].getCheckedNodes()

			// if (!ckdClass[0]) {
			// 	this.$message.error("请先选择班级")
			// 	return
			// }
			// ckdClass = ckdClass[0].data
			// if (ckdClass.type != 'bj') {
			// 	this.$message.error("只能在班级中添加学生")
			// 	return
			// }

			this.student_id = 0;
			this.showTeacherInfo = true;
		},

		getDeptRoles() {
			this.$http.post("/api/sys_dept_role_list").then(res => {
				this.deptRoleList = res.data.data;
			});
		},

		handleNodeClick(data) {
			// console.log(data);
			this.deptId = data.id;

			this.deptInfo = data;
			this.ckdTreeData = [data];
			this.getStudents();
		},
		handleSelectionChange(val) {
			let _this = this;
			_this.multipleSelection = val;
		},
		exportXls(url) {
			let params = {
				page: 1,
				pagesize: 2000,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				office_id: this.deptId,
				joined: this.searchForm.biye,
				school_year: this.searchForm.school_year,
			};
			this.$http.post("/api/njjs_export_jobstu", params).then(res => {
				window.open(res.data.url, "_blank");
			});
		},

		handleAdd(row) {
			this.formData = {
				parent_id: row.id,
				sort: 1,
				type: "",
				name: "",
				roles: []
			};
			this.modalTitle = "新增部门";
			this.isShowModal = true;
		},
		handleModify(row) {

			this.formData = row;
			this.editDialogShow = true;
		},

		handleDel(row) {
			this.$http
				.post("/api/t_sch_students_delete", {
					id: String(row.id)
				})
				.then(res => {
					this.$message.success("删除成功");
					this.getStudents();
				});
		},
		handleCascaderChange(e) {
			// console.log(1, e);
		},
		exportZip() {
			this.importZipShow = true
		},
    j1jobUpdate() {
      let loadInstance = this.$loading({
        lock: true,
        text: '正在同步，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      })
      this.$http.post('/api/njjs_from91jobToStudent').then(res => {
        this.$message.success(res.data.msg)
        this.getStudents()
        loadInstance.close()
      })
    },
    downloadRange(url, start, end, i) {
      return new Promise(async (resolve, reject) => {
        this.$http.get(url,{responseType:'blob',headers: {range: `bytes=${start}-${end}`}}).then(res => {
          resolve({
            i,
            buffer: res.data
          })
        }).catch(err => {
          reject(err)
        })
        // 计算下载百分比  当前下载的片数/总片数
        //this.percentage = Number.parseFloat(Number((i / this.totalChunk) * 100).toFixed(2))
        //this.downloadText = `共切分:${this.totalChunk}片,第${i + 1}片上传完成`
      })
    },
    downloadAttach() {
      this.showProgress = true
      this.progressMsg = '准备文件中，请稍等...'
      this.progressPercent = 0
      this.progressStatus = 'success'
      this.$http.post('/api/njjs_downloadAttach',{class_ids: this.deptId}).then(res => {
        //轮询获取进度
        this.timer = setInterval(() => {
          this.$http.post('/api/njjs_download_stu_file_progress').then(res => {
            let data = res.data
            if (data.filename) {
              this.progressMsg = '打包完成，准备下载'
              this.progressPercent = 100
              let loadInstance = this.$loading({
                lock: true,
                text: '下载中，请稍等...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
              })
              clearInterval(this.timer)
              //从服务器下载文件
              let url = `/api/downloadLocalFile?filename=${data.filename}`
              this.$http.get(url, {responseType: 'blob',headers:{range:"bytes=0-1"}}).then(res => {
                let result = res.headers['content-range'].split('/')
                let size = parseInt(result[1],10)
                this.totalChunk = Math.ceil(size / this.chunkSize)
                let arr = []
                for (let i = 0; i < this.totalChunk; i++) {
                  const start = i * this.chunkSize
                  let end
                  if (i === this.totalChunk - 1) {
                    end = size - 1 // 最后一片的结束位置
                  } else {
                    end = (i + 1) * this.chunkSize - 1
                  }
                  console.log(`Chunk ${i + 1}: Start: ${start}, End: ${end}, FileSize:${size}`)
                  arr.push(this.downloadRange(url, start, end, i))
                }
                Promise.all(arr).then(res => {
                  const blobs = res.sort(item => item.i - item.i).map(item => (item.buffer))
                  console.log(`结果集长度为：${blobs.length}`)
                  let blob = new Blob(blobs, {type: 'application/octet-stream;charset=UTF-8'})
                  let downloadElement = document.createElement('a')
                  let href = window.URL.createObjectURL(blob) // 创建下载的链接
                  downloadElement.style.display = 'none'
                  downloadElement.href = href
                  downloadElement.download = decodeURIComponent(data.filename) // 下载后文件名
                  document.body.appendChild(downloadElement)
                  downloadElement.click() // 点击下载
                  document.body.removeChild(downloadElement) // 下载完成移除元素
                  window.URL.revokeObjectURL(href)
                  loadInstance.close()
                  this.showProgress = false
                })
              })
            } else if (data == 'compress') {
              this.progressPercent = 100
              this.progressMsg = '正在打包压缩文件，过程有点长，请稍等...'
            } else if (data == 'failed') {
              this.progressMsg = '下载失败，请重新下载'
              this.progressStatus = 'exception'
              clearInterval(this.timer)
            } else {
              let arr = data.split('/')
              this.progressPercent = Math.round(Number(arr[0])/Number(arr[1])*10000)/100
            }
          }).catch(e => {
            clearInterval(this.timer)
          })
        },2000)
      })
    },
	},
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>
<style scoped lang="less" type="text/less">
.app-container {
	.treeContainer {
		height: 100vh;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
		border: 1px solid rgba(220, 223, 230, 0.6);
		margin-right: 20px;
		padding-right: 10px;
		overflow: auto;

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}

.nodetag {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #409eff;
	color: #fff;
	border-radius: 4px;
}
</style>
  